import { memo, useEffect } from "react"

import {
  ROOM_STATUSES,
  RoomStatus,
  useRoomStatus,
} from "../../hooks/useRoomStatus"

import { changeProDvxLedColor } from "../../redux/proDvx/actions"
import { PRODVX_LED_COLORS } from "../../redux/proDvx/constants"
import { useAppDispatch } from "../../redux/store"

type ProDvxProps = {
  proDvxToken: string
}

const ProDvxLedController = ({ proDvxToken }: ProDvxProps) => {
  const dispatch = useAppDispatch()
  const roomStatus = useRoomStatus()

  useEffect(() => {
    dispatch(changeProDvxLedColor(getLedColor(roomStatus), proDvxToken))
  }, [dispatch, proDvxToken, roomStatus])

  return null
}

const getLedColor = (roomStatus: RoomStatus) => {
  switch (roomStatus) {
    case ROOM_STATUSES.FREE:
      return PRODVX_LED_COLORS.FREE
    case ROOM_STATUSES.PENDING_CHECK_IN:
      return PRODVX_LED_COLORS.CHECK_IN
    case ROOM_STATUSES.IN_USE:
      return PRODVX_LED_COLORS.BOOKED
    case ROOM_STATUSES.IS_OFFLINE:
    default:
      return PRODVX_LED_COLORS.OFF
  }
}

export default memo(ProDvxLedController)
