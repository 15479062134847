import React, { useCallback, useEffect, useState } from "react"

import classNames from "classnames"

import { NAVIGATE_HOME_TIMEOUT } from "../../constants"
import Button from "../Button"
import { Trans } from "@joan/joan-core"

import CheckSVG from "../../assets/icons/check.svg"
import CloseSVG from "../../assets/icons/close.svg"

import "./style.sass"

type CloseProps = {
  isAutoCloseConfirm?: boolean
  onCloseConfirm: () => void
  isAutoSubmitConfirm?: never
  onSubmitConfirm?: never
}

type SubmitProps = {
  isAutoSubmitConfirm?: boolean
  onSubmitConfirm: () => void
  isAutoCloseConfirm?: never
  onCloseConfirm?: never
}

type BaseConfirmProps = {
  isDisabled?: boolean
  isSubmitting?: boolean
}

type Props = BaseConfirmProps & (CloseProps | SubmitProps)

const ConfirmActions = ({
  isAutoCloseConfirm,
  isAutoSubmitConfirm,
  onCloseConfirm,
  onSubmitConfirm,
  isDisabled,
  isSubmitting,
}: Props) => {
  const [remainingTime, setRemainingTime] = useState(
    NAVIGATE_HOME_TIMEOUT / 1000,
  )
  const [hasConfirmed, setHasConfirmed] = useState(false)

  const isTimeExpired = remainingTime < 1

  const className = classNames("confirm-actions", {
    "confirm-actions--auto-close-confirm": isAutoCloseConfirm,
    "confirm-actions--auto-submit-confirm": isAutoSubmitConfirm,
    "confirm-actions--disabled": isDisabled,
    "confirm-actions--auto-submitting": isSubmitting,
    "confirm-actions--time-expired":
      (isAutoCloseConfirm || isAutoSubmitConfirm) && isTimeExpired,
  })

  const handleCloseConfirm = useCallback(() => {
    if (!isDisabled && onCloseConfirm) {
      onCloseConfirm()
      setHasConfirmed(true)
    }
  }, [isDisabled, onCloseConfirm])

  const handleSubmitConfirm = useCallback(() => {
    if (onSubmitConfirm) {
      onSubmitConfirm()
      setHasConfirmed(true)
    }
  }, [onSubmitConfirm])

  useEffect(() => {
    if (
      (isAutoCloseConfirm || isAutoSubmitConfirm) &&
      !isDisabled &&
      !hasConfirmed
    ) {
      const intervalId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalId)

            if (isAutoCloseConfirm) {
              handleCloseConfirm()
            } else if (isAutoSubmitConfirm) {
              handleSubmitConfirm()
            }
            return 0
          }
          return prevTime - 1
        })
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [
    isAutoCloseConfirm,
    isAutoSubmitConfirm,
    isDisabled,
    hasConfirmed,
    handleCloseConfirm,
    handleSubmitConfirm,
  ])

  if (onCloseConfirm) {
    return (
      <div className={className}>
        {isAutoCloseConfirm && !isTimeExpired && (
          <div className="confirm-actions__description">
            <Trans
              i18nKey="This screen will auto-dismiss in {{remainingTime}} sec."
              default="This screen will auto-dismiss in {{remainingTime}} sec."
              values={{ remainingTime }}
            />
          </div>
        )}
        <Button
          className="AutoConfirm Close"
          isIconButton
          onClick={onCloseConfirm}
          isDisabled={isDisabled}
        >
          <CloseSVG />
        </Button>
      </div>
    )
  }

  if (onSubmitConfirm) {
    return (
      <div className={className}>
        {isAutoSubmitConfirm && !isTimeExpired && !isDisabled && (
          <div className="confirm-actions__description">
            <Trans
              i18nKey="Auto-booked for 30 min after {{remainingTime}} sec."
              default="Auto-booked for 30 min after {{remainingTime}} sec."
              values={{ remainingTime }}
            />
          </div>
        )}
        <Button
          className="AutoConfirm Submit"
          isIconButton
          onClick={onSubmitConfirm}
          isDisabled={isDisabled}
          isLoading={isSubmitting}
        >
          <CheckSVG />
        </Button>
      </div>
    )
  }

  return
}

export default ConfirmActions
