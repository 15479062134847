import { useMemo } from "react"

import dayjs from "dayjs"

import { isConfirmedEvent } from "../utils"

import {
  selectConfirmedEvents,
  selectIsCheckInAllowed,
} from "../redux/events/selectors"
import { CalendarEvent } from "../redux/events/types"
import { interfaceConfigSelector } from "../redux/selectors"
import { useAppSelector } from "../redux/store"

export const useCanCheckIntoMeetings = (
  calendarEvent: CalendarEvent | null,
): boolean => {
  const constants = useAppSelector((state) => state.constants)
  const config = interfaceConfigSelector(constants)

  const confirmedEvents = useAppSelector(selectConfirmedEvents)
  const isCheckInAllowed = useAppSelector(selectIsCheckInAllowed)

  const { checkIntoMeetings: checkIntoTimeout } = config

  return useMemo(() => {
    if (!calendarEvent || !isCheckInAllowed) return false

    if (
      calendarEvent.confirmed ||
      isConfirmedEvent(calendarEvent.id, confirmedEvents)
    ) {
      return false
    }

    const now = dayjs()
    const eventStart = dayjs(calendarEvent.start)

    const checkinPeriodStart = eventStart.subtract(checkIntoTimeout, "minute")
    const checkInPeriodEnd = eventStart.add(checkIntoTimeout, "minute")

    return now.isAfter(checkinPeriodStart) && now.isBefore(checkInPeriodEnd)
  }, [calendarEvent, isCheckInAllowed, confirmedEvents, checkIntoTimeout])
}
