import dayjs from "dayjs"

import { DEFAULT_EXTEND_MINUTES } from "../../constants"

import { CalendarEvent } from "../../redux/events/types"

export const newEndDate = (
  event: CalendarEvent,
  nextEvent: CalendarEvent | null,
) => {
  const currentEventEnd = dayjs(event.end)

  if (nextEvent === null) {
    return currentEventEnd.add(DEFAULT_EXTEND_MINUTES, "minute")
  }

  const nextEventStart = dayjs(nextEvent.start)
  const minutesUntilNextEvent = nextEventStart.diff(
    currentEventEnd,
    "minute",
    true,
  )

  if (!nextEvent || minutesUntilNextEvent > DEFAULT_EXTEND_MINUTES) {
    return currentEventEnd.add(DEFAULT_EXTEND_MINUTES, "minute")
  } else {
    return currentEventEnd.add(minutesUntilNextEvent, "minute")
  }
}
