import { AnyAction } from "redux"

import {
  FETCH_CALENDAR_ERROR,
  FETCH_CALENDAR_SUCCESS,
} from "../events/constants"
import {
  FETCH_CONSTANTS_ERROR,
  FETCH_CONSTANTS_PAYMENT_REQUIRED,
  FETCH_CONSTANTS_SUCCESS,
} from "./constants"
import { ConstantsState } from "./types"

const initialState: ConstantsState = {
  ampm: 0,
  calendar_names: "",
  calendar: null,
  calendars: [],
  customButtons: null,
  customContent: null,
  deviceType: null,
  featureset: {
    eventCancelation: "0",
    displayCharging: false,
    touchscreen: false,
    sound: false,
    heartbeat: "3",
    checkinto: false,
    checkintoTimeout: 5,
    meetLater: false,
    meetNow: false,
    meetNowTimeout: 30,
    noWiFi: false,
    roaming: "1",
    roamingThreshold: "70",
    roamingHysteresis: "15",
    dtim: "100",
    personalizedDevice: false,
    cleaningStatus: false,
    config: {
      version: 1,
      interface: {
        "8": {
          timeFormat: 1,
          logo: "",
          meetNowDuration: 30,
          meetLater: true,
          cancelMeetings: 1,
          checkIntoMeetings: 5,
          showMeetingSubject: true,
          showMeetingOrganizer: true,
          showTentativeMeetings: true,
        },
      },
    },
  },
  interface: 8,
  locale: "en",
  logo: null,
  name: "",
  room_properties: [],
  sleepUntil: null,
  text: "",
  timezone: "",
  version: "",
  warning_l1: "",
  warning_l2: false,
  setup: true,
  featureToggles: {
    track_wifi_crypto: false,
  },
  trackWiFiCrypto: false,
  user_id: null,
  user_email: null,
  accountType: 0,
  roomSelect: false,
  uuid: null,
  featureFlags: [],
  lastCleaning: null,
  pv2features: 0,
  calendarNames: "",
  DEBUG: false,
  REMOTE_DEBUGGER_SRC: null,
  GTM_DEVICE_ID: null,
  company: {
    id: "",
    name: "",
    analyticsDomain: "",
    country: "",
  },
  prodvx: null,
  logoObjectURL: null,
}

const constantsReducer = (
  state: ConstantsState = initialState,
  action: AnyAction,
): ConstantsState => {
  const { type, payload } = action

  switch (type) {
    case FETCH_CONSTANTS_SUCCESS:
      const { warning_l2: warning_l2_dirty } = payload
      let warning_l2 = false

      if (typeof warning_l2_dirty === "string") {
        warning_l2 = warning_l2_dirty === "true"
      }

      return { ...payload, warning_l2 }

    // the warning_12 is used to show payment required message in the app
    case FETCH_CONSTANTS_PAYMENT_REQUIRED:
      return { ...state, warning_l2: true }

    case FETCH_CONSTANTS_ERROR:
    case FETCH_CALENDAR_ERROR:
      if (!payload.setup) {
        return state
      } else {
        return { ...state, setup: true }
      }

    case FETCH_CALENDAR_SUCCESS:
      if (!payload.setup) {
        return {
          ...state,
          setup: false,
        }
      } else {
        return state
      }

    default:
      return state
  }
}

export default constantsReducer
