import { memo, useEffect } from "react"

import {
  ROOM_STATUSES,
  RoomStatus,
  useRoomStatus,
} from "../../hooks/useRoomStatus"

const CrestronLedController = () => {
  const roomStatus = useRoomStatus()

  useEffect(() => {
    adjustLedLight(roomStatus)
  }, [roomStatus])

  return null
}
const adjustLedLight = (roomStatus: RoomStatus) => {
  switch (roomStatus) {
    case ROOM_STATUSES.FREE:
      switchOffLedLight()
      window.Crestron?.sendBooleanSignal("USB_GREEN_LED_CONTROL", true)
      window.Crestron?.sendIntegerSignal("USB_GREEN_LED_BRIGHTNESS", 100)
      break
    case ROOM_STATUSES.PENDING_CHECK_IN:
      switchOffLedLight()
      window.Crestron?.sendBooleanSignal("USB_RED_LED_CONTROL", true)
      window.Crestron?.sendBooleanSignal("USB_GREEN_LED_CONTROL", true)
      window.Crestron?.sendIntegerSignal("USB_RED_LED_BRIGHTNESS", 100)
      window.Crestron?.sendIntegerSignal("USB_GREEN_LED_BRIGHTNESS", 80)
      break
    case ROOM_STATUSES.IN_USE:
      switchOffLedLight()
      window.Crestron?.sendBooleanSignal("USB_RED_LED_CONTROL", true)
      window.Crestron?.sendIntegerSignal("USB_RED_LED_BRIGHTNESS", 100)
      break
    case ROOM_STATUSES.IS_OFFLINE:
    default:
      switchOffLedLight()
  }
}

const switchOffLedLight = () => {
  window.Crestron?.sendBooleanSignal("USB_RED_LED_CONTROL", false)
  window.Crestron?.sendBooleanSignal("USB_GREEN_LED_CONTROL", false)
  window.Crestron?.sendBooleanSignal("USB_BLUE_LED_CONTROL", false)
}

export default memo(CrestronLedController)
