import React, { ReactNode } from "react"

import { nameOrEmail } from "../../utils"
import InlineSVG from "../InlineSVG"
import { t } from "@joan/joan-core"

import { Atendee } from "../../redux/events/types"

import PeopleSVG from "../../assets/icons/people.svg"

function attendeeSummary(attendees: Atendee[]) {
  const t_attendees = t("attendees") // TODO: Use proper plural with t(key, count)
  if (attendees.length > 3) {
    return `${attendees.length} ${t_attendees}`
  } else {
    return attendees.map((a) => nameOrEmail({ ...a, email: a.mail })).join(", ")
  }
}

type Props = {
  attendees?: Atendee[]
  children?: ReactNode
  showIcon?: boolean
}

const Attendees = ({ attendees, children, showIcon = true }: Props) => {
  if (!attendees?.length) {
    return
  }

  return (
    <p className="Attendees">
      {showIcon && (
        <InlineSVG>
          <PeopleSVG />
        </InlineSVG>
      )}{" "}
      {children ? children : attendeeSummary(attendees ?? [])}
    </p>
  )
}

export default Attendees
