import React, { useState } from "react"

import { Trans } from "@joan/joan-core"

import Button from "../../../components/Button"
import GridSelect, { Item } from "../../../components/GridSelect"

import CheckSVG from "../../../assets/icons/check.svg"

type Props = {
  slots: Item[]
  durations: Item[]
  slot: number | null
  duration: number | null
  onChangeTimeslot: (timeslot: {
    slot: number | null
    duration: number | null
  }) => void
  onOpenInitialForm: () => void
}

const TimeslotForm = ({
  slots,
  slot,
  durations,
  duration,
  onChangeTimeslot,
  onOpenInitialForm,
}: Props) => {
  const [, setSlotCount] = useState(19)
  const [areSlotsExpanded, setAreSlotsExpanded] = useState(false)

  return (
    <>
      <div className="form">
        <div>
          <div className="sub-title">
            <Trans>Start time</Trans>
          </div>
          <GridSelect
            items={slots}
            value={slot ?? null}
            onChange={(value) => onChangeTimeslot({ slot: value, duration })}
            onExpand={() => {
              setSlotCount((prev) => prev + 32)
              setAreSlotsExpanded(true)
            }}
            isExpanded={areSlotsExpanded}
            showExpander
          />
        </div>

        <div>
          <div className="sub-title">
            <Trans>Duration</Trans>
          </div>
          <GridSelect
            items={durations}
            value={duration ?? null}
            onChange={(value) => onChangeTimeslot({ slot, duration: value })}
            isExpanded
            isHorizontal
          />
        </div>
      </div>

      <div className="confirm-actions">
        <Button className="Confirm" onClick={onOpenInitialForm} isIconButton>
          <CheckSVG />
        </Button>
      </div>
    </>
  )
}

export default TimeslotForm
