import React, { useEffect } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { Route } from "react-router-dom"

import AnimatedRouter from "./AnimatedRouter"
import { IS_DEMO_MODE, PATHS } from "./constants"
import LedLightsController from "./controllers/LedLights/LedLightsController"
import { useNetworkStatus } from "./hooks/useNetworkStatus"
import useOrientation from "./hooks/useOrientation"
import Onboarding from "./Onboarding"
import ErrorHandledRoute from "./routes/ErrorHandledRoute"
import Home from "./routes/Home"
import { PWA as PWAInstructions } from "./routes/Instructions"
import MeetLater from "./routes/MeetLater"
import MeetNow from "./routes/MeetNow"
import NotFound from "./routes/NotFound"
import QRCode from "./routes/QRCode"
import Timetable from "./routes/Timetable"
import Version from "./routes/Version"
import Webhook from "./routes/Webhook"
import {
  isDesktop,
  isInDevelopment,
  isIPad,
  isPWAinBrowser,
  isQRCodeCustomButton,
  isWebHookCustomButton,
} from "./utils"
import { langDirection, setLocale, TransProvider } from "@joan/joan-core"
import { TLocale } from "@joan/joan-core/dist/interfaces/Locale.interface"
import localizedFormat from "dayjs/plugin/localizedFormat"
import throttle from "lodash.throttle"

import { useAppSelector } from "./redux/store"

import Loader from "./components/Loader"
import PaymentNotification from "./components/PaymentNotification"

import "./App.sass"

dayjs.extend(localizedFormat)

const BETA_PERIOD = true

const JoanOnTablets = () => {
  const constants = useAppSelector((state) => state.constants)
  const { customButtons } = constants

  const customButtonAction = customButtons ? customButtons.action : null

  const setLanguageToHtmlTag = ({
    language,
    direction,
  }: {
    language: string
    direction: string
  }) => {
    const root = document.getElementsByTagName("html")[0]

    if (language) root?.setAttribute("lang", language)
    if (direction) root?.setAttribute("dir", direction)
  }

  const handleTranslationChange = (lang: TLocale) => {
    if (!lang) {
      console.warn("Desired language is empty! Skipping setLocale.")
      return
    }

    const direction = langDirection(lang)
    setLanguageToHtmlTag({ language: lang, direction })

    // Language fallback hack
    if (lang.toLowerCase() === "en-us") {
      lang = "en-gb"
    }

    setLocale(lang)
  }

  const { isPortrait, isLandscape } = useOrientation()

  const { warning_l2, locale } = useAppSelector((state) => state.constants)

  const { isOffline } = useNetworkStatus()

  const appClassName = classNames({
    App: true,
    iPad: isIPad(),
    isPortrait,
    isLandscape,
    isOffline,
  })

  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight / 100
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    const debouncedHandleResize = throttle(handleResize, 100)

    if (!isDesktop()) {
      handleResize()
    }

    window.addEventListener("resize", debouncedHandleResize)

    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  }, [])

  if (warning_l2) {
    return (
      <div className={appClassName}>
        <PaymentNotification />
      </div>
    )
  }

  if (isPWAinBrowser() && !isInDevelopment() && !BETA_PERIOD) {
    return (
      <div className={appClassName}>
        <PWAInstructions />
      </div>
    )
  }

  return (
    <div className={appClassName}>
      <TransProvider
        lang={locale}
        catalog="tablet"
        onChange={handleTranslationChange}
        loader={<Loader variant="large" theme="light" />}
      >
        <Onboarding>
          <AnimatedRouter>
            <ErrorHandledRoute path={PATHS.HOME} exact component={Home} />
            <ErrorHandledRoute
              path={PATHS.MEET_NOW}
              exact
              component={MeetNow}
            />
            <ErrorHandledRoute
              path={PATHS.MEET_LATER}
              exact
              component={MeetLater}
            />
            <ErrorHandledRoute
              path={PATHS.TIMETABLE}
              exact
              component={Timetable}
            />
            <ErrorHandledRoute path={PATHS.VERSION} exact component={Version} />
            {isQRCodeCustomButton(customButtonAction) && (
              <ErrorHandledRoute
                path={PATHS.QR_CODE}
                exact
                component={QRCode}
              />
            )}
            {isWebHookCustomButton(customButtonAction) && (
              <ErrorHandledRoute
                path={PATHS.WEB_HOOK}
                exact
                component={Webhook}
              />
            )}
            {!IS_DEMO_MODE && <Route path="*" component={NotFound} />}
          </AnimatedRouter>
        </Onboarding>
        <LedLightsController />
      </TransProvider>
    </div>
  )
}

export default JoanOnTablets
