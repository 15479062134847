import React, { ReactNode } from "react"

import FitClamp from "react-fitclamp"

import "./style.sass"

const textClasses = [
  "text-1",
  "text-2",
  "text-3",
  "text-4",
  "text-5",
  "text-6",
  "text-7",
]

type Props = {
  children: ReactNode
}

const MeetingTitle = ({ children }: Props) => {
  const childrenString =
    typeof children === "string" ? children : JSON.stringify(children)

  return (
    <h1 className="MeetingTitle">
      <FitClamp
        key={childrenString}
        className="MeetingTitle__text"
        textClasses={textClasses}
      >
        {children}
      </FitClamp>
    </h1>
  )
}

export default MeetingTitle
