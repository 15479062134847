import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import { PATHS } from "../../constants"
import { Trans } from "@joan/joan-core"

import { fetchQR } from "../../redux/constants/actions"
import { CustomButton } from "../../redux/constants/types"
import { useAppSelector } from "../../redux/store"

import Dialog from "../../components/Dialog"
import Loader from "../../components/Loader"

import "./style.sass"

const QRCode = () => {
  const history = useHistory()

  const { constants, device } = useAppSelector((state) => ({
    constants: state.constants,
    device: state.device,
  }))

  const customButtons: CustomButton | null = constants.customButtons

  const [isFetching, setFetching] = useState<boolean>(false)
  const [imageSrc, setImageSrc] = useState<string | null>(null)

  useEffect(() => {
    const fetchQRImage = async () => {
      setFetching(true)
      try {
        const qrImageSrc = await fetchQR(
          device.UUID,
          customButtons?.payload?.message ?? "",
        )
        if (qrImageSrc) {
          setImageSrc(qrImageSrc)
        }
      } catch (error) {
        console.error("Error fetching QR image:", error)
      } finally {
        setFetching(false)
      }
    }

    if (customButtons?.payload?.message) {
      fetchQRImage()
    }
  }, [customButtons, device.UUID])

  return (
    <Dialog
      title={customButtons?.title}
      isAutoCloseConfirm
      showFooter
      onClose={() => history.push(PATHS.HOME)}
      className="dialog--qr-code"
    >
      {isFetching ? (
        <Loader variant="large" theme="dark" />
      ) : imageSrc ? (
        <>
          <div className="qr">
            <img src={imageSrc} alt="QR Code" />
          </div>
          <div className="title">
            <Trans>Scan code for</Trans>{" "}
            <strong>{customButtons?.title ?? ""}</strong>
          </div>
        </>
      ) : (
        <div>
          <Trans>No QR code available</Trans>
        </div>
      )}
    </Dialog>
  )
}

export default QRCode
