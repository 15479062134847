import posthog from "posthog-js"

import { getProDvxDeviceInfoURL } from "./api/urls"
import { DEVICE_TYPES } from "./constants"
import { ObjectToUnion } from "./types/sharedTypes"

import { Company } from "./redux/constants/types"

declare global {
  interface Window {
    posthog: any
  }
}

const POSTHOG_API_KEY = import.meta.env.VITE_APP_POSTHOG_API_KEY
const POSTHOG_URL = import.meta.env.VITE_APP_POSTHOG_HOST
const POSTHOG_TEAM_ID = "team_id"
const POSTHOG_TEAM_DOMAIN = "team_domain"

const isPosthogActive = !!POSTHOG_API_KEY && !!POSTHOG_URL
let isUserIdentified = false

/**
 * https://app.posthog.com/
 *
 * Event and user tracking.
 */
if (isPosthogActive) {
  console.log("Posthog is being initialized.")

  posthog.init(POSTHOG_API_KEY, {
    api_host: POSTHOG_URL,
    disable_session_recording: true,
    // to use heatmaps the autocapture must be enabled
    autocapture: true,
  })

  /**
   * We expose posthog for marketing and Google Tag Manager.
   */
  window.posthog = posthog
}

export const analyticsIdentifyUser = async (
  deviceId: string,
  company?: Company,
  proDvxToken?: string,
) => {
  if (!isPosthogActive || !company || isUserIdentified) {
    return
  }

  const { analyticsDomain, country, id, name } = company

  posthog.group(POSTHOG_TEAM_ID, id, {
    team_name: name,
    team_id: id,
    team_domain: analyticsDomain,
  })

  if (analyticsDomain) {
    posthog.group(POSTHOG_TEAM_DOMAIN, analyticsDomain, {
      team_domain: analyticsDomain,
    })
  }

  const device_type = await getDeviceType(proDvxToken)

  posthog.identify(deviceId, {
    device_id: deviceId,
    team_name: name,
    team_id: id,
    team_domain: analyticsDomain,
    team_country: country,
    device_type,
  })

  posthog.setPersonProperties({
    device_id: deviceId,
    team_name: name,
    team_id: id,
    team_domain: analyticsDomain,
    team_country: country,
    device_type,
  })

  isUserIdentified = true
}

export const enablePosthogRecording = () => {
  if (!isPosthogActive) {
    return
  }

  posthog.startSessionRecording()
}

export const resetPosthog = () => {
  if (!isPosthogActive) {
    return
  }

  posthog.reset()
}

export const analyticsEvent = (eventName: SupportedEvents, metadata?: any) => {
  if (isPosthogActive) {
    posthog.capture(eventName, metadata)
  }
}

/**
 * Currently supported events, described and updated here:
 *
 * https://docs.google.com/spreadsheets/d/1LlRcY9IH6IFN_nsV9CTp1stSBTX4XC2VnZrxkiVIKmw/edit#gid=773844549
 */
export const SUPPORTED_EVENTS = Object.freeze({
  ROOM_BOOKED: "JOT/user/room_booked",
  ROOM_BOOKING_CANCEL: "JOT/user/room_booking_cancel",
  ROOM_BOOKING_CHECK_IN: "JOT/user/room_booking_checkin",
  ROOM_BOOKING_END: "JOT/user/room_booking_end",
  ROOM_BOOKING_EXTEND: "JOT/user/room_booking_extend",
})
type SupportedEvents = ObjectToUnion<typeof SUPPORTED_EVENTS>

const getDeviceType = async (proDvxToken?: string) => {
  if (window.Crestron) {
    return DEVICE_TYPES.CRESTRON
  }

  if (proDvxToken) {
    return (await detectProDvxDevice(proDvxToken))
      ? DEVICE_TYPES.PRO_DVX
      : DEVICE_TYPES.JOT
  }

  return DEVICE_TYPES.JOT
}

const detectProDvxDevice = async (token: string) => {
  try {
    const response = await fetch(getProDvxDeviceInfoURL(token))
    return !!response.ok
  } catch {
    return false
  }
}
