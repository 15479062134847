import { useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import { ObjectToUnion } from "../types/sharedTypes"
import { isConfirmedEvent, isEventExtended } from "../utils"
import { useNetworkStatus } from "./useNetworkStatus"

import { selectFeatureset } from "../redux/constants/selectors"
import {
  selectConfirmedEvents,
  selectCurrentEvent,
  selectEvents,
  selectNextEvent,
} from "../redux/events/selectors"
import { useAppSelector } from "../redux/store"

const ROOM_STATUS_CHECK_INTERVAL = 10_000

export const ROOM_STATUSES = {
  FREE: "free",
  PENDING_CHECK_IN: "pendingCheckIn",
  IN_USE: "inUse",
  IS_OFFLINE: "isOffline",
} as const

export type RoomStatus = ObjectToUnion<typeof ROOM_STATUSES>

/**
 * Custom hook to determine the current status of a room based on various conditions.
 *
 * @returns {RoomStatus} The current status of the room.
 *
 * @remarks
 * The status is updated periodically based on the current time and the conditions provided.
 *
 * @example
 * const roomStatus = useRoomStatus();
 */

export const useRoomStatus = (): RoomStatus => {
  const [currentTime, setCurrentTime] = useState<Dayjs>(dayjs)
  const [status, setStatus] = useState<RoomStatus>(ROOM_STATUSES.IS_OFFLINE)

  const { events = [] } = useAppSelector(selectEvents)
  const currentEvent = useAppSelector(selectCurrentEvent)
  const nextEvent = useAppSelector(selectNextEvent)
  const confirmedEvents = useAppSelector(selectConfirmedEvents)
  const { checkinto: checkInto = false, checkintoTimeout = 0 } =
    useAppSelector(selectFeatureset)

  const currentEventId = currentEvent?.id
  const event = currentEvent || nextEvent
  const startTime = event?.start

  // optimistic event created on the device should be considered as checked in
  const isCheckedIn =
    isConfirmedEvent(currentEventId, confirmedEvents) ||
    (currentEvent && isEventExtended(currentEvent) && currentEvent.isOptimistic)

  const { isOffline } = useNetworkStatus()

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs())
    }, ROOM_STATUS_CHECK_INTERVAL)

    return () => interval && clearInterval(interval)
  }, [])

  useEffect(() => {
    const checkInTimeFrameStart = dayjs(startTime).subtract(
      checkintoTimeout || 0,
      "minutes",
    )
    const checkInTimeFrameEnd = dayjs(startTime).add(
      checkintoTimeout || 0,
      "minutes",
    )

    setStatus(() => {
      if (isOffline) {
        return ROOM_STATUSES.IS_OFFLINE
      }

      if (events.length === 0) {
        return ROOM_STATUSES.FREE
      }

      if (
        (currentEventId && !checkInto) ||
        (currentEvent && checkInto && isCheckedIn)
      ) {
        return ROOM_STATUSES.IN_USE
      }

      if (
        checkInto &&
        !isCheckedIn &&
        currentTime.isAfter(checkInTimeFrameStart) &&
        currentTime.isBefore(checkInTimeFrameEnd)
      ) {
        return ROOM_STATUSES.PENDING_CHECK_IN
      }

      return ROOM_STATUSES.FREE
    })
  }, [
    isOffline,
    currentEventId,
    checkInto,
    isCheckedIn,
    currentEvent,
    checkintoTimeout,
    currentTime,
    startTime,
    events.length,
  ])

  return status
}
