import React, { ReactNode } from "react"

import CancelEventContentDialog from "../../Timetable/components/CancelEventContentDialog"
import { getTime, Trans } from "@joan/joan-core"

import { checkIn } from "../../../redux/events/actions"
import { CalendarEvent } from "../../../redux/events/types"
import { useAppDispatch, useAppSelector } from "../../../redux/store"

import Button from "../../../components/Button"

import CheckSVG from "../../../assets/icons/check.svg"
import CloseSVG from "../../../assets/icons/close.svg"

import "./CheckIntoMeetingCard.sass"

type MeetDialogProps = {
  title: string
  content: ReactNode
  className?: string
}

type Props = {
  event: CalendarEvent
  canCancelMeetings: boolean
  onMeetFinishClick: (id: string) => void
  onOpenFinishMeetDialog: (meet: MeetDialogProps) => void
}

const CheckIntoMeetingCard = ({
  event,
  canCancelMeetings,
  onMeetFinishClick,
  onOpenFinishMeetDialog,
}: Props) => {
  const dispatch = useAppDispatch()

  const isCheckInLoading = useAppSelector((state) => state.checkIn.isLoading)
  const isFinishMeetingLoading = useAppSelector(
    (state) => state.finishMeeting.isLoading,
  )

  const { id, organizer, start, end, summary, on_spot = false } = event

  const disabled = isFinishMeetingLoading || isCheckInLoading

  const handleOpenFinishMeetDialog = () => {
    onMeetFinishClick(id)
    onOpenFinishMeetDialog({
      title: summary,
      content: (
        <CancelEventContentDialog
          title={summary}
          organizer={organizer}
          start={start}
          end={end}
          isOnSpot={on_spot}
        />
      ),
      className: "dialog--cancel-event-confirmation",
    })
  }

  return (
    <div className="Card CheckIn">
      <div className="title">
        <Trans>Check into meeting</Trans>
      </div>

      <div className="actions">
        <Button
          onClick={() => dispatch(checkIn(id, getTime(end)))}
          className="Confirm"
          isLoading={isCheckInLoading}
          disabled={disabled}
          isIconButton
        >
          <CheckSVG />
        </Button>

        {canCancelMeetings && (
          <Button
            onClick={handleOpenFinishMeetDialog}
            className="Cancel"
            isLoading={isFinishMeetingLoading}
            disabled={disabled}
            isIconButton
          >
            <CloseSVG />
          </Button>
        )}
      </div>
    </div>
  )
}

export default CheckIntoMeetingCard
