import React from "react"

import CrestronLedController from "./CrestronLedController"
import ProDvxLedController from "./ProDvxLedController"

import { selectProDvxToken } from "../../redux/constants/selectors"
import { selectIsLoadedEvents } from "../../redux/events/selectors"
import { useAppSelector } from "../../redux/store"

const LedLightsController = () => {
  const proDvxToken = useAppSelector(selectProDvxToken)
  const isLoadedEvents = useAppSelector(selectIsLoadedEvents)
  const isCrestronDevice = "Crestron" in window

  return (
    <div>
      {isLoadedEvents && (
        <>
          {!!proDvxToken && <ProDvxLedController proDvxToken={proDvxToken} />}
          {isCrestronDevice && <CrestronLedController />}
        </>
      )}
    </div>
  )
}

export default LedLightsController
