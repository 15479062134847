import React, { ReactNode, useRef } from "react"

import classNames from "classnames"

import { useNetworkStatus } from "../../../hooks/useNetworkStatus"
import { getTimeNow } from "../../../utils"
import MeetContentDialog from "./MeetContentDialog"
import { getTime, t, Trans } from "@joan/joan-core"

import { CalendarEvent } from "../../../redux/events/types"

import Button from "../../../components/Button"
import Card from "../../../components/Card"
import Datestring from "../../../components/Datestring"
import Duration from "../../../components/Duration"
import Organizer from "../../../components/Organizer"
import Timestamp from "../../../components/Timestamp"

import MoreVerticalSVG from "../../../assets/icons/more-vertical.svg"

import "./NextMeetingCard.sass"

type MeetDialogProps = {
  title: string
  content: ReactNode
  className?: string
}

type NextMeetingCardProps = {
  event: CalendarEvent | null
  onOpenMeetDetailsDialog: (meet: MeetDialogProps) => void
}

const NextMeetingCard = ({
  event,
  onOpenMeetDetailsDialog,
}: NextMeetingCardProps) => {
  const forwardedRef = useRef<HTMLDivElement>(null)
  const { isOnline } = useNetworkStatus()

  const { summary, description, start, end } = event ?? {}

  const now = getTimeNow()
  const isNextMeeting = start && end
  const startDate = start ? getTime(start) : now
  const isMeetingInTheFuture = now.day() < startDate.day()

  const nextMeetingClassNames = classNames({
    NextMeetingCard: true,
    NoMeetingsCard: !isNextMeeting,
  })

  const handleOpenMeetDialog = () => {
    if (!event) return

    onOpenMeetDetailsDialog({
      title: event.summary,
      content: (
        <MeetContentDialog
          organizer={event.organizer}
          attendees={event.attendees}
          description={event.description}
          isOnSpot={event.on_spot ?? false}
        />
      ),
      className: "dialog--meet-next",
    })
  }

  return (
    <Card forwardedRef={forwardedRef} className={nextMeetingClassNames}>
      <div className="container">
        {isNextMeeting ? (
          <>
            <div className="meeting-organizer-info">
              <h2 className="meeting-title">
                {summary || description || t("Booked")}
              </h2>
              {event && (
                <Organizer
                  organizer={event.organizer}
                  isOnSpot={event.on_spot}
                />
              )}
            </div>

            <Duration>
              <div className="date">
                {isMeetingInTheFuture && (
                  <Datestring date={start} isInline isLongDay />
                )}
              </div>
              <div className="time">
                <Timestamp time={start} isInline /> &ndash;{" "}
                <Timestamp time={end} isInline />
              </div>
            </Duration>
          </>
        ) : (
          <Trans>No meetings scheduled for today.</Trans>
        )}

        {isNextMeeting && isOnline && (
          <div className="meeting-actions">
            <Button onClick={handleOpenMeetDialog} isIconButton>
              <MoreVerticalSVG />
            </Button>
          </div>
        )}
      </div>
    </Card>
  )
}

export default NextMeetingCard
