import { Dayjs } from "dayjs"

import { analyticsEvent, SUPPORTED_EVENTS } from "../../analytics"
import { fetchOptions, signedFetchOptions } from "../../api/fetch"
import {
  calendarURL,
  cancelMeetingURL,
  eventURL,
  finishMeetingURL,
} from "../../api/urls"
import {
  filterOptimistic,
  formatEventTitleWithJoanTag,
  getTimeNow,
  retryFetchUntil,
} from "../../utils"
import { AppDispatch, RootState } from "../store"
import {
  CANCEL_MEETING_ERROR,
  CANCEL_MEETING_START,
  CANCEL_MEETING_SUCCESS,
  CHECK_IN_ERROR,
  CHECK_IN_START,
  CHECK_IN_SUCCESS,
  EXTEND_MEETING_ERROR,
  EXTEND_MEETING_START,
  EXTEND_MEETING_SUCCESS,
  FETCH_CALENDAR_ERROR,
  FETCH_CALENDAR_START,
  FETCH_CALENDAR_SUCCESS,
  FINISH_MEETING_ERROR,
  FINISH_MEETING_START,
  FINISH_MEETING_SUCCESS,
  MEET_LATER_ERROR,
  MEET_LATER_START,
  MEET_LATER_SUCCESS,
  MEET_NOW_DURATION,
  MEET_NOW_ERROR,
  MEET_NOW_START,
  MEET_NOW_SUCCESS,
} from "./constants"
import { EventResponse, MeetRequest, Person } from "./types"
import { getNextEventFromNow, getTime, t } from "@joan/joan-core"
import { DayJS } from "@joan/joan-core/dist/helpers/date.helpers"

// CALENDAR
export const fetchCalendarStart = (UUID: string) => ({
  type: FETCH_CALENDAR_START,
  payload: { UUID },
})

export const fetchCalendarSuccess = (
  events: EventResponse[],
  confirmed: string[],
) => ({
  type: FETCH_CALENDAR_SUCCESS,
  payload: { events, confirmed },
})

export const fetchCalendarError = (error: any) => ({
  type: FETCH_CALENDAR_ERROR,
  payload: error,
})

export const fetchCalendar =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID },
    } = getState()

    try {
      dispatch(fetchCalendarStart(UUID))
      const response = await fetch(calendarURL(UUID), fetchOptions)

      if (response.status === 200) {
        const json = await response.json()

        if (!json.setup) {
          const { events: calendarEvents, confirmed } = json

          let events = []
          if (typeof calendarEvents === "string") {
            events = JSON.parse(calendarEvents)
          }

          dispatch(fetchCalendarSuccess(events, confirmed))
        } else {
          dispatch(fetchCalendarError(json))
        }
      } else {
        dispatch(fetchCalendarError(response.body))
      }
    } catch (e) {
      dispatch(fetchCalendarError(e))
    }
  }

// MEET NOW
export const meetNowStart = ({ start, end, summary }: MeetRequest) => ({
  type: MEET_NOW_START,
  payload: { start, end, summary },
})

export const meetNowSuccess = (json: {
  id?: string
  start: DayJS
  end: DayJS
  summary: string
  organizer: Person
}) => ({
  type: MEET_NOW_SUCCESS,
  payload: json,
})

export const meetNowError = (error: any) => ({
  type: MEET_NOW_ERROR,
  payload: error,
})

export const meetNow =
  (meetNowDuration = MEET_NOW_DURATION, isAutoConfirm = true) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID, csrftoken },
      events: { events },
    } = getState()

    const start = getTimeNow()
    let end = start.add(meetNowDuration, "minute")
    const nextMeeting = getNextEventFromNow(filterOptimistic(events))

    if (nextMeeting) {
      const nextStart = getTime(nextMeeting.start)
      if (nextStart.isBefore(end)) {
        end = nextStart
      }
    }

    // summary needs to have "(Joan)" to be detected as on_spot meeting by BE
    const summary = formatEventTitleWithJoanTag(t("Occupied"))

    dispatch(
      meetNowStart({
        start: start.toISOString(),
        end: end.toISOString(),
        summary,
      }),
    )

    let resp
    try {
      resp = await fetch(eventURL(UUID), {
        ...signedFetchOptions(UUID, csrftoken),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({
          start,
          end,
          subject: summary,
          auto_confirm: isAutoConfirm,
        }),
      })

      if (resp.status === 200) {
        const json = await resp.json()
        dispatch(meetNowSuccess(json))
        analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKED, { id: json.id })

        await dispatch(fetchCalendar())
      } else {
        dispatch(meetNowError(resp.body))
      }
    } catch (e) {
      dispatch(meetNowError(e))
    }

    return resp
  }

// MEET LATER
export const meetLaterStart = ({ start, end, title }: MeetRequest) => ({
  type: MEET_LATER_START,
  payload: { start, end, title },
})

export const meetLaterSuccess = (json: {
  id: string
  start: DayJS
  end: DayJS
  summary: string
  organizer: Person
}) => ({
  type: MEET_LATER_SUCCESS,
  payload: json,
})

export const meetLaterError = (error: any) => ({
  type: MEET_LATER_ERROR,
  payload: error,
})

export const meetLater =
  (start: Dayjs, end: Dayjs, title: string, isAutoConfirm: boolean) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID, csrftoken },
    } = getState()

    let resp
    try {
      dispatch(meetLaterStart({ start, end, title }))

      resp = await fetch(eventURL(UUID), {
        ...signedFetchOptions(UUID, csrftoken),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({
          start: start.toISOString(),
          end: end.toISOString(),
          // subject needs to have "(Joan)" to be detected as on_spot meeting by BE
          subject: formatEventTitleWithJoanTag(title),
          auto_confirm: isAutoConfirm,
        }),
      })

      if (resp.status === 200) {
        const json = await resp.json()
        dispatch(meetLaterSuccess(json))
        analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKED, { id: json.id })
        await dispatch(fetchCalendar())

        return resp
      } else {
        dispatch(meetLaterError(resp.body))
        return resp
      }
    } catch (e) {
      dispatch(meetLaterError(e))
      return resp
    }
  }

// CHECK INTO MEETING
export const checkInStart = ({ id, end }: { id: string; end: Dayjs }) => ({
  type: CHECK_IN_START,
  payload: { id, end },
})

export const checkInSuccess = () => ({
  type: CHECK_IN_SUCCESS,
})

export const checkInError = (error: any) => ({
  type: CHECK_IN_ERROR,
  payload: error,
})

export const checkIn =
  (id: string, end: Dayjs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { csrftoken, UUID },
    } = getState()

    const start = getTimeNow().toISOString()

    let resp
    try {
      dispatch(checkInStart({ id, end }))

      resp = await fetch(eventURL(UUID, id), {
        ...signedFetchOptions(UUID, csrftoken),
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({
          action: "checkinto",
          start,
          end,
        }),
      })

      if (resp.ok) {
        dispatch(checkInSuccess())
        analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKING_CHECK_IN, { id })
        await dispatch(fetchCalendar())

        return resp
      } else {
        dispatch(checkInError(resp.body))
        return resp
      }
    } catch (e) {
      dispatch(checkInError(e))
      return resp
    }
  }

// FINISH MEETING
export const finishMeetingStart = (id: string) => ({
  type: FINISH_MEETING_START,
  payload: id,
})

export const finishMeetingSuccess = (json: { id: string }) => ({
  type: FINISH_MEETING_SUCCESS,
  payload: json,
})

export const finishMeetingError = (error: any) => ({
  type: FINISH_MEETING_ERROR,
  payload: error,
})

export const finishMeeting =
  (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID },
    } = getState()

    try {
      dispatch(finishMeetingStart(id))

      const response = (await retryFetchUntil(
        finishMeetingURL(UUID, id),
        fetchOptions,
        { retryIn: [1000, 3000, 10000] },
      )) as { id: string }
      dispatch(finishMeetingSuccess(response))
      analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKING_END, { id: response.id })

      return { ok: true, response }
    } catch (e) {
      dispatch(finishMeetingError(e))

      return { ok: false, error: e }
    }
  }

// CANCEL MEETING
export const cancelMeetingStart = (id: string) => ({
  type: CANCEL_MEETING_START,
  payload: id,
})

export const cancelMeetingSuccess = (json: { id: string }) => ({
  type: CANCEL_MEETING_SUCCESS,
  payload: json,
})

export const cancelMeetingError = (error: any) => ({
  type: CANCEL_MEETING_ERROR,
  payload: error,
})

export const cancelMeeting =
  (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID },
    } = getState()

    try {
      dispatch(cancelMeetingStart(id))

      const response = (await retryFetchUntil(
        cancelMeetingURL(UUID, id),
        fetchOptions,
        { retryIn: [1000, 3000, 10000] },
      )) as { id: string }

      dispatch(cancelMeetingSuccess(response))
      analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKING_CANCEL, { id: response.id })

      return { ok: true, response }
    } catch (e) {
      dispatch(cancelMeetingError(e))

      return { ok: false, error: e }
    }
  }

// EXTEND MEETING
export const extendMeetingStart = (id: string) => ({
  type: EXTEND_MEETING_START,
  payload: id,
})

export const extendMeetingSuccess = () => ({
  type: EXTEND_MEETING_SUCCESS,
})

export const extendMeetingError = (error: any) => ({
  type: EXTEND_MEETING_ERROR,
  payload: error,
})

export const extendMeeting =
  (id: string, newEnd: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID, csrftoken },
    } = getState()

    if (!id || !newEnd) return

    try {
      dispatch(extendMeetingStart(id))

      const response = await fetch(eventURL(UUID, id), {
        ...signedFetchOptions(UUID, csrftoken),
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ action: "extend", end: newEnd }),
      })

      dispatch(extendMeetingSuccess())
      analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKING_EXTEND, {
        id: id,
      })

      await dispatch(fetchCalendar())

      return { ok: true, response }
    } catch (e) {
      dispatch(extendMeetingError(e))

      return { ok: false, error: e }
    }
  }

// RECALCULATE EVENTS
// Broadcast at end of event, etc.
export const recalculateEvents = () => ({
  type: "RECALCULATE_EVENTS",
})
