import React, { useCallback } from "react"

import classNames from "classnames"
import { useHistory, useLocation } from "react-router-dom"

import { PATHS } from "../../constants"
import Button from "../Button"
import CustomButton from "../CustomButton"
import { REPLACES } from "./constants"
import { Trans } from "@joan/joan-core"

import { selectIsBookingDisabled } from "../../redux/events/selectors"
import { interfaceConfigSelector } from "../../redux/selectors"
import { useAppSelector } from "../../redux/store"

import "./style.sass"

const Toolbar = () => {
  const history = useHistory()
  const location = useLocation()

  const {
    constants,
    isMeetNowLoading,
    isMeetLaterLoading,
    isFinishMeetingLoading,
    isBookingDisabled,
    currentMeeting,
    config,
  } = useAppSelector((state) => ({
    constants: state.constants,
    config: interfaceConfigSelector(state.constants),
    currentMeeting: state.events.currentEvent,
    isCheckInLoading: state.checkIn.isLoading,
    isMeetNowLoading: state.meetNow.isLoading,
    isMeetLaterLoading: state.meetLater.isLoading,
    isFinishMeetingLoading: state.finishMeeting.isLoading,
    isBookingDisabled: selectIsBookingDisabled(state),
  }))

  const handleBack = () => {
    history.push("/home")
  }

  const handleMeetNow = () => {
    history.push("/meet-now")
  }

  const handleMeetLater = () => {
    history.push("/meet-later")
  }

  const handleTimetable = () => {
    history.push("/timetable")
  }

  const disabledButtons = ({
    currentMeeting,
    canMeetNow,
    canMeetLater,
    isMeetNowLoading,
    isMeetLaterLoading,
    isFinishMeetingLoading,
    isBookingDisabled,
  }: Record<string, any>) => {
    const isMeetNowDisabled =
      !canMeetNow ||
      isBookingDisabled ||
      !!currentMeeting ||
      isMeetNowLoading ||
      isFinishMeetingLoading

    const isMeetLaterDisabled = !canMeetLater || isBookingDisabled
    const isConfirmDisabled =
      isBookingDisabled || isMeetNowLoading || isMeetLaterLoading

    const isTimetableDisabled = false

    return {
      isMeetNowDisabled,
      isMeetLaterDisabled,
      isConfirmDisabled,
      isTimetableDisabled,
    }
  }

  const portraitPositionClassNames = ({
    isMeetNowDisabled,
    isMeetLaterDisabled,
    isTimetableDisabled,
  }: Record<string, any>) => {
    const meetNow =
      isMeetLaterDisabled && isTimetableDisabled
        ? "wholeButton fromLeftButton1"
        : isMeetLaterDisabled || isTimetableDisabled
          ? "halfButton fromLeftButton1"
          : "thirdButton fromLeftButton1"

    const meetLater =
      isMeetNowDisabled && isTimetableDisabled
        ? "wholeButton fromLeftButton1"
        : isMeetNowDisabled
          ? "halfButton fromLeftButton1"
          : isTimetableDisabled
            ? "halfButton fromLeftHalfButton2"
            : "thirdButton fromLeftThirdButton2"

    const timetable =
      isMeetNowDisabled && isMeetLaterDisabled
        ? "wholeButton fromLeftButton1"
        : isMeetNowDisabled || isMeetLaterDisabled
          ? "halfButton fromLeftHalfButton2"
          : "thirdButton fromLeftThirdButton3"

    return {
      meetNow,
      meetLater,
      timetable,
    }
  }

  const landscapePositionClassNames = ({
    isMeetNowDisabled,
    isMeetLaterDisabled,
    isTimetableDisabled,
  }: Record<string, any>) => {
    const meetNow =
      isTimetableDisabled && isMeetLaterDisabled
        ? "fromBottomButton1"
        : isTimetableDisabled || isMeetLaterDisabled
          ? "fromBottomButton2"
          : "fromBottomButton3"

    const meetLater = isTimetableDisabled
      ? "fromBottomButton1"
      : "fromBottomButton2"

    const timetable = "fromBottomButton1"

    return {
      meetNow,
      meetLater,
      timetable,
    }
  }

  const classNamesGenerator = ({
    portraitPositions,
    landscapePositions,
    isMeetLater,
    isTimetable,
  }: Record<any, any>) => {
    const toolbar = classNames({
      Toolbar: true,
      isMeetLater,
      isTimetable,
      isQRCode,
      isWebHook,
      isMeetNow,
      isVersion,
    })

    const meetNow = classNames({
      meetNowButton: true,
      [portraitPositions.meetNow]: true,
      [landscapePositions.meetNow]: true,
      "Button-04dp": true,
      isMeetNow,
    })

    const meetLater = classNames({
      meetLaterButton: true,
      [portraitPositions.meetLater]: true,
      [landscapePositions.meetLater]: true,
      "Button-04dp": true,
      isMeetLater,
    })

    const timetable = classNames({
      timetableButton: true,
      [portraitPositions.timetable]: true,
      [landscapePositions.timetable]: true,
      "Button-04dp": true,
      isTimetable,
    })

    const confirm = classNames({
      confirmButton: true,
      "Button-04dp": true,
      isMeetLater,
    })

    return {
      toolbar,
      meetNow,
      meetLater,
      timetable,
      confirm,
    }
  }

  const { pathname } = location
  const isMeetNow = pathname === PATHS.MEET_NOW
  const isMeetLater = pathname === PATHS.MEET_LATER
  const isTimetable = pathname === PATHS.TIMETABLE
  const isVersion = pathname === PATHS.VERSION
  const isQRCode = pathname === PATHS.QR_CODE
  const isWebHook = pathname.includes(PATHS.WEB_HOOK)

  const { meetNowDuration, meetLater } = config
  const canMeetNow = meetNowDuration !== 0
  const canMeetLater = !!meetLater

  const { customButtons } = constants ?? null

  const hasCustomButton = useCallback(
    (replace: number) => {
      if (!customButtons) {
        return false
      }

      return customButtons.replaces === replace
    },
    [customButtons],
  )

  if (constants.setup === true) return null

  const {
    isMeetNowDisabled,
    isMeetLaterDisabled,
    isConfirmDisabled,
    isTimetableDisabled,
  } = disabledButtons({
    currentMeeting,
    canMeetNow,
    canMeetLater,
    isMeetNowLoading,
    isMeetLaterLoading,
    isFinishMeetingLoading,
    isBookingDisabled,
  })

  const portraitPositions = portraitPositionClassNames({
    isMeetNowDisabled: isMeetNowDisabled && !hasCustomButton(REPLACES.MEET_NOW),
    isMeetLaterDisabled:
      isMeetLaterDisabled && !hasCustomButton(REPLACES.MEET_LATER),
    isTimetableDisabled:
      isTimetableDisabled && !hasCustomButton(REPLACES.TIMETABLE),
  })

  const landscapePositions = landscapePositionClassNames({
    isMeetNowDisabled,
    isMeetLaterDisabled,
    isTimetableDisabled,
  })

  const {
    toolbar: toolbarClassName,
    meetNow: meetNowClassName,
    meetLater: meetLaterClassName,
    timetable: timetableClassName,
    confirm: confirmClassName,
  } = classNamesGenerator({
    portraitPositions,
    landscapePositions,
    isMeetLater,
    isTimetable,
  })

  return (
    <div className={toolbarClassName}>
      {!hasCustomButton(REPLACES.MEET_NOW) ? (
        <Button
          key="meet-now"
          className={meetNowClassName}
          onMouseDown={handleMeetNow}
          isDisabled={isMeetNowDisabled}
        >
          <Trans>Meet now</Trans>
        </Button>
      ) : hasCustomButton(REPLACES.MEET_NOW) ? (
        <CustomButton
          key="custom-action"
          className={classNames(meetNowClassName, "replaceMeetNowButton")}
        />
      ) : null}

      {!hasCustomButton(REPLACES.MEET_LATER) ? (
        <Button
          key="meet-later"
          className={meetLaterClassName}
          onMouseDown={isMeetLater ? handleBack : handleMeetLater}
          isDisabled={isMeetLaterDisabled}
        >
          <Trans>Meet later</Trans>
        </Button>
      ) : hasCustomButton(REPLACES.MEET_LATER) ? (
        <CustomButton
          key="custom-action"
          className={classNames(meetLaterClassName, "replaceMeetLaterButton")}
        />
      ) : null}

      {!hasCustomButton(REPLACES.TIMETABLE) ? (
        <Button
          key="timetable"
          className={timetableClassName}
          onMouseDown={isTimetable ? handleBack : handleTimetable}
          isDisabled={isTimetableDisabled}
        >
          <Trans>Timetable</Trans>
        </Button>
      ) : hasCustomButton(REPLACES.TIMETABLE) ? (
        <CustomButton
          key="custom-action"
          className={classNames(timetableClassName, "replaceTimetableButton")}
        />
      ) : null}
    </div>
  )
}

export default Toolbar
