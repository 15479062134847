import React from "react"

import classNames from "classnames"

import { Trans } from "@joan/joan-core"

import NavHeaderBar from "../../components/NavHeaderBar"

import LogoVerticalSVG from "../../assets/joan-vertical.svg"

import "./style.sass"

const Version = () => {
  const versionClassName = classNames("Version", "Route")

  return (
    <div className={versionClassName}>
      <NavHeaderBar isShowBackButton />
      <div className="content">
        <LogoVerticalSVG className="logo" />
        <h1>Joan on Tablets</h1>
        <p>
          <Trans>Version</Trans> {import.meta.env.VITE_APP_VERSION ?? "0.0.0"}
        </p>
      </div>
    </div>
  )
}

export default Version
