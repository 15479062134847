import React, { Fragment, ReactNode, useMemo } from "react"

import classNames from "classnames"

import { useCanCheckIntoMeetings } from "../../../hooks/useCanCheckIntoMeetings"
import { useNetworkStatus } from "../../../hooks/useNetworkStatus"
import useOrientation from "../../../hooks/useOrientation"
import { getTimeNow } from "../../../utils"
import CancelEventContentDialog from "../../Timetable/components/CancelEventContentDialog"
import ExtendEventContentDialog from "../../Timetable/components/ExtendEventContentDialog"
import { newEndDate } from "../utils"
import MeetContentDialog from "./MeetContentDialog"
import { getTime, t, Trans } from "@joan/joan-core"

import { RoomProperties } from "../../../redux/constants/types"
import { selectEvents } from "../../../redux/events/selectors"
import { CalendarEvent } from "../../../redux/events/types"
import { useAppSelector } from "../../../redux/store"

import Attendees from "../../../components/Attendees"
import Button from "../../../components/Button"
import Card from "../../../components/Card"
import Datestring from "../../../components/Datestring"
import Duration from "../../../components/Duration"
import Logo from "../../../components/Logo"
import MeetingTitle from "../../../components/MeetingTitle"
import Organizer from "../../../components/Organizer"
import StatusBar from "../../../components/StatusBar"
import Timestamp from "../../../components/Timestamp"

import CloseSVG from "../../../assets/icons/close.svg"
import ExtendSVG from "../../../assets/icons/extend.svg"
import MoreVerticalSVG from "../../../assets/icons/more-vertical.svg"
import PeopleSVG from "../../../assets/icons/people_full.svg"
import WiFiOffSVG from "../../../assets/icons/wifi_off.svg"

import "./CurrentMeetingCard.sass"

type MeetDialogProps = {
  title: string
  content: ReactNode
  className?: string
}

type Props = {
  event: CalendarEvent | null
  roomProperties: RoomProperties[]
  showStatusBar: boolean
  roomName: string
  canCancelMeetings: boolean
  onOpenMeetDetailsDialog: (meet: MeetDialogProps) => void
  onMeetFinishClick: (id: string) => void
  onOpenFinishMeetDialog: (meet: MeetDialogProps) => void
  onOpenExtendMeetDialog: (meet: MeetDialogProps) => void
}

const CurrentMeetingCard = ({
  event,
  roomProperties = [],
  roomName,
  showStatusBar,
  canCancelMeetings,
  onOpenMeetDetailsDialog,
  onMeetFinishClick,
  onOpenFinishMeetDialog,
  onOpenExtendMeetDialog,
}: Props) => {
  const { isPortrait, isLandscape } = useOrientation()

  const canCheckIntoMeetings = useCanCheckIntoMeetings(event)
  const isCheckInLoading = useAppSelector((state) => state.checkIn.isLoading)
  const isFinishMeetingLoading = useAppSelector(
    (state) => state.finishMeeting.isLoading,
  )
  const isMeetNowLoading = useAppSelector((state) => state.meetNow.isLoading)

  const { events } = useAppSelector((state) => ({
    events: selectEvents(state),
  }))

  const currentEvent = events.currentEvent
  const nextEvent = events.nextEvent

  const {
    summary,
    organizer,
    attendees = [],
    start,
    end,
    on_spot = false,
  } = event ?? {}

  const meetingAttendees = attendees.filter((a) => a.mail !== organizer?.email)
  const now = getTimeNow()
  const startDate = start ? getTime(start) : now
  const endDate = end ? getTime(end) : now

  const isVacant = !(now.isAfter(startDate) && now.isBefore(endDate))
  const isNextMeeting = start && now.isBefore(startDate)
  const isNextMeetingInTheFuture = now.day() < endDate.day()
  const isNextMeetingTomorrow = now.day() + 1 === endDate.day()
  const isNextMeetingWithinWeek = now.day() + 6 > endDate.day()
  const isNextMeetingCheckIn = canCheckIntoMeetings && isNextMeeting

  const isShowMeetingInfo = !isVacant || isNextMeetingCheckIn

  const areActionsLoading =
    isCheckInLoading || isFinishMeetingLoading || isMeetNowLoading

  const isCancellable = canCancelMeetings && !areActionsLoading

  const meetingTitle = useMemo(() => {
    if (!isVacant || isNextMeetingCheckIn) {
      return summary || t("Occupied")
    }

    return t("Vacant")
  }, [isVacant, isNextMeetingCheckIn, summary])

  const roomCapacity = roomProperties[0]?.capacity ?? 0

  const isRoomAvailable = roomCapacity > 0

  const currentMeetingClassNames = classNames({
    CurrentMeeting: true,
    OccupiedCard: !isVacant,
    VacantCard: isVacant,
    NextMeetingCheckInCard: isNextMeetingCheckIn,
  })

  const handleOpenMeetDialog = () => {
    if (!event) return

    onOpenMeetDetailsDialog({
      title: meetingTitle,
      content: (
        <MeetContentDialog
          organizer={event.organizer}
          attendees={event.attendees}
          description={event.description}
          isOnSpot={event.on_spot ?? false}
        />
      ),
      className: "dialog--meet-current",
    })
  }

  const handleOpenFinishMeetDialog = () => {
    if (!event) return

    onMeetFinishClick(event.id)
    onOpenFinishMeetDialog({
      title: meetingTitle,
      content: (
        <CancelEventContentDialog
          title={meetingTitle}
          organizer={event.organizer}
          start={event.start}
          end={event.end}
          isOnSpot={event.on_spot ?? false}
        />
      ),
      className: "dialog--cancel-event-confirmation",
    })
  }

  const handleOpenExtendMeetDialog = () => {
    if (!event) return

    onMeetFinishClick(event.id)
    onOpenExtendMeetDialog({
      title: meetingTitle,
      content: (
        <ExtendEventContentDialog
          title={meetingTitle}
          organizer={event.organizer}
          resource={event.resource}
          start={event.start}
          end={event.end}
          newEnd={newEndDate(currentEvent!, nextEvent).toISOString()}
          isOnSpot={event.on_spot ?? false}
        />
      ),
      className: "dialog--extend-event-confirmation",
    })
  }

  return (
    <Card className={currentMeetingClassNames}>
      <div className="room-details">
        <div className="room-details-header">
          <div className="room-name">{roomName}</div>
          {!isVacant && isLandscape && (
            <Button
              onClick={handleOpenMeetDialog}
              className="MeetDetails"
              isIconButton
            >
              <MoreVerticalSVG />
            </Button>
          )}

          {isPortrait && <Logo variant="horizontal" />}
        </div>

        <div className="room-details-body">
          {isRoomAvailable && (
            <div className="room-details-item">
              <PeopleSVG />
              <Trans
                i18nKey="Suitable for {{roomCapacity}} people"
                default="Suitable for {{roomCapacity}} people"
                values={{ roomCapacity }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="meeting-info">
        <MeetingTitle>{meetingTitle}</MeetingTitle>

        {isShowMeetingInfo && organizer && (
          <Organizer organizer={organizer} isOnSpot={on_spot} />
        )}

        {isShowMeetingInfo && <Attendees attendees={meetingAttendees} />}
      </div>

      {(!isVacant || isNextMeeting) && (
        <div className="meeting-actions">
          <Duration>
            {isNextMeetingInTheFuture && (
              <>
                <span>{t("Until")} </span>
                {isNextMeetingTomorrow ? (
                  <span>{t("tomorrow")}</span>
                ) : isNextMeetingWithinWeek ? (
                  <span>{endDate.format("dddd")}</span>
                ) : (
                  end && <Datestring date={end} isInline />
                )}
                <span>, </span>
              </>
            )}

            {!isNextMeetingCheckIn && start && end && (
              <>
                {!isNextMeetingInTheFuture && <span>{t("Until")} </span>}
                <Timestamp time={isNextMeeting ? start : end} isInline />
              </>
            )}

            {isNextMeetingCheckIn && start && end && (
              <>
                <Timestamp time={start} isInline /> &ndash;{" "}
                <Timestamp time={end} isInline />
              </>
            )}
          </Duration>

          {!isVacant && (
            <div className="actions">
              <Button
                onClick={handleOpenExtendMeetDialog}
                className="Extend"
                isIconButton
              >
                <ExtendSVG />
              </Button>

              {canCancelMeetings && (
                <Button
                  onClick={handleOpenFinishMeetDialog}
                  className="Confirm"
                  isDisabled={!isCancellable}
                  isIconButton
                >
                  <CloseSVG />
                </Button>
              )}

              {!isVacant && isPortrait && (
                <Button
                  onClick={handleOpenMeetDialog}
                  className="MeetDetails"
                  isIconButton
                >
                  <MoreVerticalSVG />
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      {showStatusBar && (
        <StatusBar
          className="meeting-status"
          isTaken={!isVacant}
          start={start}
          end={end}
        />
      )}
    </Card>
  )
}

export default CurrentMeetingCard
