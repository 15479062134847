import React, { ReactNode, useEffect, useRef } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"

import Timestamp from "../Timestamp"

import MoreSVG from "../../assets/icons/more_horiz.svg"

import "./style.sass"

export type Item = {
  value: number
  label?: string
  isDisabled: boolean | null
  time?: Dayjs
}

type GridSelectProps = {
  items: Item[]
  value: number | null
  onChange: (value: number | null) => void
  onExpand?: () => void
  showExpander?: boolean
  isExpanded?: boolean
  isHorizontal?: boolean
  className?: string
}

const GridSelect = ({
  items = [],
  value,
  onChange,
  onExpand,
  showExpander = false,
  isExpanded,
  isHorizontal,
  className,
}: GridSelectProps) => {
  const surfaceRef = useRef<HTMLDivElement | null>(null)
  const selectedItemRef = useRef<HTMLDivElement | null>(null)
  const firstItemRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isExpanded) {
      const firstItem = firstItemRef.current
      if (firstItem) {
        firstItem.scrollIntoView()
      }
    }
  }, [isExpanded])

  useEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView()
    }
  }, [isHorizontal, value])

  const gridSelectClassName = classNames({
    GridSelect: true,
    [className || ""]: !!className,
    isExpanded,
    isHorizontal,
  })

  return (
    <div className={gridSelectClassName}>
      <div className="surface" ref={surfaceRef}>
        {isExpanded ? (
          <ItemList
            items={items}
            currentValue={value}
            onChange={onChange}
            forwardRef={(r, item) => {
              if (item.value === value) {
                selectedItemRef.current = r
              }
            }}
          />
        ) : (
          <ItemList
            items={items}
            currentValue={value}
            onChange={onChange}
            forwardRef={(r, item) => {
              if (item.value === value) {
                selectedItemRef.current = r
              }
            }}
          />
        )}
        {showExpander && (
          <div className="Item" onClick={onExpand}>
            <MoreSVG />
          </div>
        )}
      </div>
    </div>
  )
}

type ItemListProps = {
  items: Item[]
  currentValue: number | null
  forwardRef: (ref: HTMLDivElement | null, item: Item, index: number) => void
  onChange?: (value: number | null) => void
}

const ItemList = ({
  items,
  currentValue,
  forwardRef,
  onChange,
}: ItemListProps) => {
  const isItemSelected = (item: Item, currentValue: number | null) => {
    if (item?.time) {
      const itemTime = item.time
      const currentTime = dayjs(currentValue)

      return (
        itemTime.minute() === currentTime.minute() &&
        itemTime.hour() === currentTime.hour()
      )
    }
    return item.value === currentValue
  }

  const handleClick = (item: Item) => {
    onChange?.(item?.time ? item.time.valueOf() : item.value)
  }

  return items.map((item, i) => (
    <Item
      key={`item-${i}-${item.value}`}
      item={item}
      isSelected={isItemSelected(item, currentValue)}
      isDisabled={item.isDisabled}
      forwardRef={(r) => forwardRef(r, item, i)}
      onClick={() => handleClick(item)}
    >
      {item.time ? (
        <Timestamp time={item.time} />
      ) : (
        <div className="Duration">{item.label}</div>
      )}
    </Item>
  ))
}

type ItemProps = {
  children: ReactNode
  item: Item
  isSelected: boolean
  isDisabled: boolean | null
  onClick?: () => void
  forwardRef: React.Ref<HTMLDivElement>
}

const Item = ({
  children,
  item,
  isSelected,
  isDisabled,
  onClick,
  forwardRef,
}: ItemProps) => {
  const itemClassName = classNames({
    Item: true,
    isDisabled,
    isSelected,
  })

  const handleClick = !isDisabled && onClick ? onClick : undefined

  return (
    <div className={itemClassName} onClick={handleClick} ref={forwardRef}>
      {children}
    </div>
  )
}

export default GridSelect
