import React from "react"

import dayjs from "dayjs"

import { useTimeFormat } from "../../../hooks/useTimeFormat"
import { ISODate } from "../../../types/sharedTypes"
import { Trans } from "@joan/joan-core"

import { Person } from "../../../redux/events/types"

import Organizer from "../../../components/Organizer"

import "./EventContentDialog.sass"

type Props = {
  title: string
  organizer: Person
  resource: string
  start: ISODate
  end: ISODate
  newEnd: ISODate
  isOnSpot: boolean
}

const ExtendEventContentDialog = ({
  title,
  organizer,
  start,
  end,
  newEnd,
  isOnSpot,
}: Props) => {
  const { timeFormat } = useTimeFormat()

  return (
    <div className="dialog__body">
      <div className="dialog__section">
        <h5 className="dialog__label">
          <Trans>Extend meeting</Trans>
        </h5>
        <p className="dialog__value dialog__value--confirmation">
          <Trans>Are you sure you want to extend the meeting?</Trans>
        </p>
      </div>

      <div className="dialog__section dialog__section--participant">
        <p className="dialog__value dialog__value--title">{title}</p>
        <Organizer organizer={organizer} isOnSpot={isOnSpot} />
        <p className="dialog__value dialog__value--date">
          {dayjs(start).format("LL")}
        </p>
        <p className="dialog__value dialog__value--duration">
          {dayjs(start).format(timeFormat)} - {dayjs(end).format(timeFormat)}
        </p>
      </div>

      <div className="dialog__section">
        <p className="dialog__label">
          <Trans>New meeting end time</Trans>
        </p>
        <p className="dialog__value dialog__value--new-end-date">
          {dayjs(newEnd).format(timeFormat)}
        </p>
      </div>
    </div>
  )
}

export default ExtendEventContentDialog
