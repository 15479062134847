import { useCallback, useEffect, useState } from "react"

type UseMultiClickProps = {
  multiClickCallback: () => void
  singleClickCallback?: () => void
  clickCount?: number
  timeSpan?: number
}

/**
 * Custom hook to handle multiple clicks within a specified timespan.
 *
 * @param {Object} props - The properties object.
 * @param {function} props.multiClickCallback - The callback function to be called after the specified number of clicks.
 * @param {function} [props.singleClickCallback] - The callback function to be called after a single click if the multi-click condition is not met.
 * @param {number} [props.clickCount=5] - The number of clicks required to trigger the multiClickCallback.
 * @param {number} [props.timeSpan=1500] - The timespan (in milliseconds) within which the clicks must occur to trigger the multiClickCallback.
 *
 * @returns {function} - The onClick handler function to be attached to the clickable element.
 */
export const useMultiClick = ({
  multiClickCallback: callback,
  singleClickCallback,
  clickCount = 5,
  timeSpan = 1_500,
}: UseMultiClickProps) => {
  const [clicks, setClicks] = useState<number>(0)
  const [firstClick, setFirstClick] = useState<number | null>(null)

  const onClick = useCallback(() => {
    const now = Date.now()

    if (!firstClick) {
      setFirstClick(now)
      setClicks(1)
      return
    }

    if (now - firstClick >= timeSpan) {
      // Reset if timespan has passed since first click
      setFirstClick(now)
      setClicks((n) => n + 1)
      return
    }

    if (clicks + 1 >= clickCount) {
      callback()
      setClicks(0)
      setFirstClick(null)
    } else {
      setClicks((n) => n + 1)
    }
  }, [clicks, firstClick, callback, clickCount, timeSpan])

  useEffect(() => {
    let timeout = null
    if (firstClick) {
      timeout = setTimeout(() => {
        if (clicks < clickCount) {
          singleClickCallback?.()
        }
        setClicks(0)
        setFirstClick(null)
      }, timeSpan)
    }
    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [clickCount, clicks, firstClick, singleClickCallback, timeSpan])

  return onClick
}
