import { useAppSelector } from "../redux/store"

/**
 * Custom hook to determine the time format based on the application's settings.
 *
 * @param hasLeadingZero - A boolean indicating whether the hour should have a leading zero.
 * @returns An object containing the time format string and a boolean indicating if the format is AM/PM.
 */

export const useTimeFormat = (hasLeadingZero: boolean = false) => {
  const isAmPm = useAppSelector((state) => state.constants.ampm === 1)

  const h = isAmPm ? "h" : "H"

  const timeFormat = hasLeadingZero ? `${h}${h}:mm` : `${h}:mm`
  return { timeFormat, isAmPm }
}
