import { selectConstants } from "../constants/selectors"
import { interfaceConfigSelector } from "../selectors"
import { RootState } from "../store"

export const selectEvents = (state: RootState) => state.events

export const selectCurrentEvent = (state: RootState) =>
  state.events.currentEvent

export const selectNextEvent = (state: RootState) => state.events.nextEvent

export const selectConfirmedEvents = (state: RootState) =>
  state.events.confirmed

export const selectIsLoadedEvents = (state: RootState) => state.events.isLoaded

export const selectIsBookingDisabled = (state: RootState) =>
  state.constants.calendar && state.constants.calendar.indexOf("http") === 0

export const selectIsCheckInAllowed = (state: RootState) => {
  const constants = selectConstants(state)

  const interfaceConfig = interfaceConfigSelector(constants)

  const isBookingDisabled = selectIsBookingDisabled(state)

  return !!interfaceConfig.checkIntoMeetings && !isBookingDisabled
}
