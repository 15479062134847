import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"

import { IS_DEMO_MODE } from "../../constants"
import { useDialog } from "../../hooks/useDialog"
import { ISODate } from "../../types/sharedTypes"
import {
  canCancelMeeting,
  filterOptimistic,
  getTimeNow,
  orderByDate,
} from "../../utils"
import { getTime, t, Trans } from "@joan/joan-core"
import throttle from "lodash.throttle"

import { cancelMeeting } from "../../redux/events/actions"
import { selectIsBookingDisabled } from "../../redux/events/selectors"
import { Person } from "../../redux/events/types"
import { interfaceConfigSelector } from "../../redux/selectors"
import { useAppDispatch, useAppSelector } from "../../redux/store"

import Button from "../../components/Button"
import Dialog from "../../components/Dialog"
import NavHeaderBar from "../../components/NavHeaderBar"
import Organizer from "../../components/Organizer"
import Timestamp from "../../components/Timestamp"
import CancelEventContentDialog from "./components/CancelEventContentDialog"

import ArrowLeftSVG from "../../assets/icons/arrow_left.svg"
import ArrowRightSVG from "../../assets/icons/arrow_right.svg"
import CloseSVG from "../../assets/icons/close.svg"

import "./style.sass"

const Timetable = () => {
  const dispatch = useAppDispatch()

  const { config, events, isCancelMeetLoading, isBookingDisabled } =
    useAppSelector((state) => ({
      config: interfaceConfigSelector(state.constants),
      events: orderByDate(filterOptimistic(state.events.events)),
      isCancelMeetLoading: state.cancelMeeting.isLoading,
      isBookingDisabled: selectIsBookingDisabled(state),
    }))

  const now = getTimeNow()

  const [selectedMeetId, setSelectedMeetId] = useState<string | null>(null)

  const [selectedDate, setSelectedDate] = useState(now)
  const [currentPage, setCurrentPage] = useState(0)
  const [eventsPerPage, setEventsPerPage] = useState(3)

  const eventsContainerRef = useRef<HTMLDivElement | null>(null)
  const eventItemRef = useRef<HTMLDivElement | null>(null)

  const {
    isOpenDialog,
    content,
    handleOpenDialog,
    handleCloseDialog,
    dialogRef,
    className,
  } = useDialog()

  const timetableClassName = classNames({
    Timetable: true,
    Route: true,
  })

  const getSummary = (summary: string, start: ISODate, end: ISODate) => {
    const startDate = getTime(start)
    const endDate = getTime(end)
    const isOccupied = now.isAfter(startDate) && now.isBefore(endDate)

    if (!summary) {
      return isOccupied ? t("Occupied") : t("Booked")
    }

    return summary
  }

  const filteredEvents = useMemo(() => {
    return events.filter((ev) => dayjs(ev.start).isSame(selectedDate, "day"))
  }, [events, selectedDate])

  const visibleEvents = useMemo(() => {
    const start = currentPage * eventsPerPage
    const end = start + eventsPerPage
    return filteredEvents.slice(start, end)
  }, [filteredEvents, currentPage, eventsPerPage])

  const totalPages = Math.ceil(filteredEvents.length / eventsPerPage)

  const handleScrollUp = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0))
  }

  const handleScrollDown = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))
  }

  const handlePreviousDay = () => {
    setCurrentPage(0)
    setSelectedDate((prevState) => prevState.subtract(1, "day"))
  }

  const handleNextDay = () => {
    setCurrentPage(0)
    setSelectedDate((prevState) => prevState.add(1, "day"))
  }

  const handleSelectMeet = (id: string) => setSelectedMeetId(id)

  const handleOpenConfirmCancelDialog = (
    title: string,
    organizer: Person,
    start: ISODate,
    end: ISODate,
    isOnSpot: boolean,
  ) => {
    handleOpenDialog({
      title,
      content: (
        <CancelEventContentDialog
          title={title}
          organizer={organizer}
          start={start}
          end={end}
          isOnSpot={isOnSpot}
        />
      ),
      className: "dialog--cancel-event-confirmation",
    })
  }

  const handleMeetCancelSubmit = useCallback(async () => {
    if (!selectedMeetId) return

    const response = await dispatch(
      IS_DEMO_MODE
        ? cancelMeeting(selectedMeetId)
        : cancelMeeting(selectedMeetId),
    )

    if (response?.ok) {
      handleCloseDialog()
    }
  }, [dispatch, handleCloseDialog, selectedMeetId])

  useEffect(() => {
    const calculateEventsPerPage = () => {
      const container = eventsContainerRef.current
      const event = eventItemRef.current

      if (container && event) {
        const containerHeight = container.clientHeight
        const eventHeight = event.getBoundingClientRect().height

        if (eventHeight > 0) {
          setEventsPerPage(Math.floor(containerHeight / eventHeight))
        }
      }
    }

    calculateEventsPerPage()

    const handleResize = throttle(calculateEventsPerPage, 100)

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <div className={timetableClassName}>
        <NavHeaderBar isShowBackButton />

        <div className="sub-title">
          <Trans>Timetable</Trans>
        </div>

        <div className="selected-date">{selectedDate.format("LL")}</div>

        <div className="events" ref={eventsContainerRef}>
          {visibleEvents.map((ev, i) => {
            return (
              <div
                className="event"
                key={`ev-${i}-${ev.summary}`}
                ref={eventItemRef}
              >
                <div className="event-details">
                  <div className="summary">
                    {getSummary(ev.summary, ev.start, ev.end)}
                  </div>

                  {ev.organizer && (
                    <Organizer
                      organizer={ev.organizer}
                      isOnSpot={ev.on_spot ?? false}
                    />
                  )}

                  <div className="duration">
                    <Timestamp time={ev.start} leadingZero /> -{" "}
                    <Timestamp time={ev.end} leadingZero />
                  </div>
                </div>
                <div className="event-actions">
                  <Button
                    onClick={() => {
                      handleSelectMeet(ev.id)
                      handleOpenConfirmCancelDialog(
                        getSummary(ev.summary, ev.start, ev.end),
                        ev.organizer,
                        ev.start,
                        ev.end,
                        ev.on_spot,
                      )
                    }}
                    isDisabled={
                      !canCancelMeeting(config, ev) && !isBookingDisabled
                    }
                    isIconButton
                  >
                    <CloseSVG />
                  </Button>
                </div>
              </div>
            )
          })}

          {visibleEvents.length === 0 && (
            <div className="no-events">
              <p>
                <Trans>No meetings scheduled for today.</Trans>
              </p>
            </div>
          )}
        </div>

        <div className="actions">
          <div>
            <Button
              onClick={handleScrollUp}
              isDisabled={currentPage === 0}
              isIconButton
            >
              <ArrowLeftSVG style={{ transform: "rotate(90deg)" }} />
            </Button>
            <Button
              onClick={handleScrollDown}
              isDisabled={
                currentPage === totalPages - 1 || visibleEvents.length === 0
              }
              isIconButton
            >
              <ArrowLeftSVG style={{ transform: "rotate(-90deg)" }} />
            </Button>
          </div>

          <div>
            <Button
              onClick={handlePreviousDay}
              isIconButton
              isDisabled={dayjs(selectedDate.subtract(1, "day")).isBefore(
                dayjs(),
                "day",
              )}
            >
              <ArrowLeftSVG />
            </Button>

            <Button onClick={handleNextDay} isIconButton>
              <ArrowRightSVG />
            </Button>
          </div>
        </div>
      </div>

      {isOpenDialog && (
        <Dialog
          ref={dialogRef}
          onSubmit={handleMeetCancelSubmit}
          onClose={handleCloseDialog}
          className={className}
          isSubmitting={isCancelMeetLoading}
          showFooter
        >
          {content}
        </Dialog>
      )}
    </>
  )
}

export default Timetable
