import React from "react"

import { Trans } from "@joan/joan-core"

import { Atendee, Person } from "../../../redux/events/types"

import Organizer from "../../../components/Organizer"

import "./MeetContentDialog.sass"

type Props = {
  organizer: Person
  attendees?: Atendee[]
  description: string
  isOnSpot: boolean
}

const MeetContentDialog = ({
  organizer,
  attendees = [],
  description,
  isOnSpot,
}: Props) => {
  return (
    <div className="dialog__body">
      <div className="dialog__section dialog__section--participant">
        <h5 className="dialog__label">
          <Trans>Organizer and Attendees</Trans>:
        </h5>

        <Organizer organizer={organizer} isOnSpot={isOnSpot} />

        {!!attendees.length && (
          <p className="dialog__value dialog__value--attendees">
            {attendees.map(
              (attendee, i) =>
                `${attendee.name}${i === attendees.length - 1 ? "" : ", "}`,
            )}
          </p>
        )}
      </div>

      {!!description && (
        <div className="dialog__section dialog__section--description">
          <h5 className="dialog__label">
            <Trans>Description</Trans>:
          </h5>
          <p className="dialog__value">{description}</p>
        </div>
      )}
    </div>
  )
}

export default MeetContentDialog
