import React, { useEffect, useState } from "react"

import classNames from "classnames"

import { clamp } from "../../utils"

import "./style.sass"

const dotProgressStyle = (progress: number) => ({
  left: `calc(${progress / 100} * (100vw - 6rem))`,
})

const REFRESH_INTERVAL = 1000

type Props = {
  className?: string
  start?: string
  end?: string
  isTaken?: boolean
}

const StatusBar = ({ className, start, end, isTaken }: Props) => {
  const [, setLastRefreshedAt] = useState(new Date())

  useEffect(() => {
    if (!isTaken) {
      return
    }

    const interval = setInterval(() => {
      setLastRefreshedAt(new Date())
    }, REFRESH_INTERVAL)

    return () => interval && clearInterval(interval)
  }, [isTaken])

  const getProgress = () => {
    if (!isTaken || !start || !end) {
      return 0
    }

    const nowTime = new Date().getTime()
    const startTime = new Date(start).getTime()
    const endTime = new Date(end).getTime()
    const allTime = endTime - startTime
    const spentTime = nowTime - startTime

    return clamp(0, (spentTime / allTime) * 100, 100)
  }

  const StatusBarClassNames = classNames({
    StatusBar: true,
    [className || ""]: !!className,
    isTaken: !!isTaken,
    isVacant: !isTaken,
  })

  const boundProgress = getProgress()

  return (
    <div className={StatusBarClassNames}>
      {isTaken && (
        <div className="dot-progress" style={dotProgressStyle(boundProgress)} />
      )}
    </div>
  )
}

export default StatusBar
