import React from "react"

import { useHistory, useLocation } from "react-router-dom"

import { PATHS } from "../../constants"
import { useMultiClick } from "../../hooks/useMultiClick"
import { LogoVariant, LogoVariantType } from "./constants"

import { fetchConstants } from "../../redux/constants/actions"
import { fetchCalendar } from "../../redux/events/actions"
import { useAppDispatch, useAppSelector } from "../../redux/store"

import LogoHorizontalSVG from "../../assets/joan-negative-horiz.svg"
import LogoVerticalSVG from "../../assets/joan-vertical.svg"

import "./style.sass"

type Props = {
  variant?: LogoVariantType
}

const Logo = ({ variant }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { logoObjectURL, logo } = useAppSelector((state) => state.constants)

  const location = useLocation()
  const { pathname } = location || {}

  const multiClickCallback = () => {
    history.push(PATHS.VERSION)
  }

  const singleClickCallback = () => {
    dispatch(fetchCalendar())
    dispatch(fetchConstants())
    if (pathname !== PATHS.HOME) {
      history.push(PATHS.HOME)
    }
  }

  const handleOnClick = useMultiClick({
    multiClickCallback,
    singleClickCallback,
  })

  const renderLogoSVG = () => {
    if (!logo && !logoObjectURL) {
      if (!variant) {
        return (
          <>
            <LogoVerticalSVG className="vertical" />
            <LogoHorizontalSVG className="horizontal" />
          </>
        )
      }
      return variant === LogoVariant.VERTICAL ? (
        <LogoVerticalSVG className="vertical" />
      ) : (
        <LogoHorizontalSVG className="horizontal" />
      )
    }
    return null
  }

  return (
    <div className="Logo" onClick={handleOnClick}>
      {logo && logoObjectURL ? (
        <img src={logoObjectURL} alt="Joan" />
      ) : (
        renderLogoSVG()
      )}
    </div>
  )
}

export default Logo
