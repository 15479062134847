import { analyticsIdentifyUser } from "../../analytics"
import { fetchOptions } from "../../api/fetch"
import {
  constantsNewURL,
  constantsURL,
  getImageURL,
  qrURL,
  webHookURL,
} from "../../api/urls"
import { AppDispatch, RootState } from "../store"
import {
  FETCH_CONSTANTS_ERROR,
  FETCH_CONSTANTS_PAYMENT_REQUIRED,
  FETCH_CONSTANTS_START,
  FETCH_CONSTANTS_SUCCESS,
} from "./constants"
import { ConstantsResponse } from "./types"

export const fetchConstantsStart = (UUID: string) => ({
  type: FETCH_CONSTANTS_START,
  payload: { UUID },
})

export const fetchConstantsSuccess = (constants: ConstantsResponse) => ({
  type: FETCH_CONSTANTS_SUCCESS,
  payload: constants,
})

export const fetchConstantsError = (error: any) => ({
  type: FETCH_CONSTANTS_ERROR,
  payload: error,
})
export const fetchConstantsPaymentRequiredError = () => ({
  type: FETCH_CONSTANTS_PAYMENT_REQUIRED,
})

const fetchConstantsNoAuth = async (UUID: string, dispatch: AppDispatch) => {
  try {
    dispatch(fetchConstantsStart(UUID))
    const response = await fetch(constantsURL(UUID), fetchOptions)

    if (response.status === 200) {
      const json = await response.json()
      const { setup, room_properties, featureset } = json

      if (!setup) {
        dispatch(
          fetchConstantsSuccess({
            ...json,
            featureset: JSON.parse(featureset || "{}"),
            room_properties: JSON.parse(room_properties || "{}")[0],
          }),
        )
      } else {
        dispatch(fetchConstantsError(json))
      }

      return json
    } else {
      dispatch(fetchConstantsError(response.body))
    }
  } catch (e) {
    dispatch(fetchConstantsError(e))
  }
  return {}
}

const fetchConstantsWithAuth = async (
  uuid: string,
  dispatch: AppDispatch,
  getState: () => RootState,
) => {
  try {
    dispatch(fetchConstantsStart(uuid))
    const response = await fetch(constantsNewURL(uuid), fetchOptions)

    if (response.status === 200) {
      const json = await response.json()
      const { setup } = json

      if (!setup) {
        const { logo, company, prodvx } = json
        const { constants } = getState()
        const { logo: logoURL, logoObjectURL } = constants

        analyticsIdentifyUser(uuid, company, prodvx?.access_token)

        if (logoURL !== logo || (!logoObjectURL && logo)) {
          const logoObjectURLNew = await fetchLogo(logo)
          dispatch(
            fetchConstantsSuccess({
              ...constants,
              ...json,
              logo,
              logoObjectURL: logoObjectURLNew
                ? logoObjectURLNew
                : logoObjectURL,
            }),
          )

          return
        }

        dispatch(
          fetchConstantsSuccess({
            ...constants,
            ...json,
            logo,
          }),
        )

        return
      } else {
        dispatch(fetchConstantsError(json))
      }

      return
    }

    if (response.status === 402) {
      dispatch(fetchConstantsPaymentRequiredError())
      return
    }

    dispatch(fetchConstantsError(response.body))
  } catch (e) {
    dispatch(fetchConstantsError(e))
  }
}

/**
 * @remarks
 * We can't replace the `fetchConstantsNoAuth`  with  the `fetchConstantsWithAuth` because
 * the `fetchConstantsWithAuth` returns 401 when the user is not authenticated.
 * So we need to call the fetchConstantsNoAuth before user is authenticated
 * and call `fetchConstantsWithAuth` when the user is authenticated.
 */
export const fetchConstants =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID },
      auth: { authenticated },
    } = getState()
    if (!authenticated) {
      await fetchConstantsNoAuth(UUID, dispatch)
      return
    }
    await fetchConstantsWithAuth(UUID, dispatch, getState)
  }

/**
 * Fetches a logo image from the specified URL.
 *
 * @param logo - The URL of the logo image.
 * @returns A Promise that resolves to a string representing the logo image, or null if the fetch fails.
 */

const fetchLogo = async (logo: string | null): Promise<string | null> => {
  if (!logo) {
    return null
  }
  try {
    const logoResponse = await fetch(getImageURL(logo), {
      ...fetchOptions,
      headers: { "Content-Type": "image/png" },
    })

    if (!logoResponse.ok) {
      return null
    }

    const blob = await logoResponse.blob()
    return blob ? URL.createObjectURL(blob) : null
  } catch {
    return null
  }
}

export const fetchQR = async (UUID: string, message: string) => {
  const response = await fetch(
    `${qrURL(UUID)}?width=406&height=406&mode=3&invert=false&grayscale=1&text=${encodeURIComponent(
      message,
    )}`,
    {
      ...fetchOptions,
      headers: {
        "Content-Type": "image/bmp",
      },
    },
  )

  if (!response.ok) {
    return null
  }

  const blob = await response.blob()

  return URL.createObjectURL(blob)
}

export const triggerWebhook = async (UUID: string) => {
  const response = await fetch(webHookURL(UUID), {
    ...fetchOptions,
    method: "POST",
  })

  if (!response.ok) {
    return null
  }

  return "ok"
}
