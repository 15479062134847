import { combineReducers } from "redux"

import { appReducer } from "./app/appSlice"
import authReducer from "./auth/reducers"
import constantsReducer from "./constants/reducers"
import deviceReducer from "./device/reducers"
import { eventsWithCurrentAndNext } from "./events/reducers"
import proDvxReducer from "./proDvx/reducers"
import uiReducer from "./ui/reducers"
import { makeLoadingReducer } from "./utils"

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  cancelMeeting: makeLoadingReducer("CANCEL_MEETING"),
  checkIn: makeLoadingReducer("CHECK_IN"),
  constants: constantsReducer,
  device: deviceReducer,
  events: eventsWithCurrentAndNext,
  extendMeeting: makeLoadingReducer("EXTEND_MEETING"),
  finishMeeting: makeLoadingReducer("FINISH_MEETING"),
  meetLater: makeLoadingReducer("MEET_LATER"),
  meetNow: makeLoadingReducer("MEET_NOW"),
  proDvx: proDvxReducer,
  ui: uiReducer,
})
