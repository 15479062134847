import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import { PATHS } from "../../constants"
import { Trans } from "@joan/joan-core"

import { triggerWebhook } from "../../redux/constants/actions"
import { useAppSelector } from "../../redux/store"

import Dialog from "../../components/Dialog"
import Loader from "../../components/Loader"

import CheckSVG from "../../assets/icons/check_circle.svg"
import ErrorSVG from "../../assets/icons/error_circle.svg"

import "./style.sass"

const Webhook = () => {
  const history = useHistory()

  const { constants, device } = useAppSelector((state) => ({
    constants: state.constants,
    device: state.device,
  }))

  const { customButtons } = constants ?? null

  const [isFetching, setFetching] = useState<boolean>(false)
  const [isWebhookSuccessful, setWebhookSuccessful] = useState<boolean>(false)

  useEffect(() => {
    const runWebhookTrigger = async () => {
      setFetching(true)
      try {
        const response = await triggerWebhook(device.UUID)

        if (response === "ok") {
          setWebhookSuccessful(true)
        }
      } catch (error) {
        console.error("Error fetching QR image:", error)
      } finally {
        setFetching(false)
      }
    }

    runWebhookTrigger()

    return () => {
      setFetching(false)
      setWebhookSuccessful(false)
    }
  }, [customButtons, device])

  return (
    <Dialog
      title={customButtons?.title}
      isAutoCloseConfirm
      showFooter
      onClose={() => history.push(PATHS.HOME)}
      className="dialog--web-hook"
    >
      {isFetching ? (
        <Loader variant="large" theme="dark" />
      ) : isWebhookSuccessful ? (
        <>
          <div className="icon">
            <CheckSVG />
          </div>
          <div className="title">
            <Trans
              i18nKey="Request for <1>{{title}} succeeded</1>"
              default="Request for <1>{{title}} succeeded</1>"
              values={{ title: customButtons?.title }}
              components={{ 1: <strong /> }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="icon">
            <ErrorSVG />
          </div>
          <div className="title">
            <Trans
              i18nKey="Request for <1>{{title}} failed</1>"
              default="Request for <1>{{title}} failed</1>"
              values={{ title: customButtons?.title }}
              components={{ 1: <strong /> }}
            />
          </div>
        </>
      )}
    </Dialog>
  )
}

export default Webhook
