import { formatEventTitleWithJoanTag, isInDevelopment } from "../utils"

export const HOST = import.meta.env.VITE_APP_HOST

export const PROTOCOL = !isInDevelopment()
  ? "HTTPS"
  : HOST === "joan-test.joan.app"
    ? "HTTPS"
    : "HTTP"

export const DEVICE_SIZE = -2

export const API_URL = `${PROTOCOL}://${HOST}`
export const API_URL_V2 = `${API_URL}/api/2.0`

export const loginURL = (uuid: string) => `${API_URL}/tablet/add/${uuid}/`

export const calendarURL = (uuid: string) => `${API_URL}/calendar/${uuid}/`

export const constantsURL = (uuid: string) => `${API_URL}/constants/${uuid}/`

export const constantsNewURL = (uuid: string) =>
  `${API_URL}/api/2.0/device/${uuid}/constants/`

export const bookURL = (
  uuid: string,
  start: string,
  end: string,
  title?: string,
) =>
  `${API_URL}/calendar/${uuid}/book/${start}/${end}/${
    title ? `?meetingName=${formatEventTitleWithJoanTag(title)}` : ``
  }`

export const finishMeetingURL = (uuid: string, id: string) =>
  `${API_URL}/calendar/${uuid}/finish/${id}/?noshow=false`

export const cancelMeetingURL = (uuid: string, id: string) =>
  `${API_URL}/calendar/${uuid}/cancel/${id}/?noshow=false`

export const eventURL = (uuid: string, id?: string): string =>
  id
    ? `${API_URL_V2}/device/${uuid}/event/${id}/`
    : `${API_URL_V2}/device/${uuid}/event/`

export const AUTH_URL = `${API_URL}/devices/is-authorized/`

export const qrURL = (uuid: string) =>
  `${PROTOCOL}://${HOST}/api/2.0/device/${uuid}/custom-button/qr-code/`

export const webHookURL = (uuid: string) =>
  `${PROTOCOL}://${HOST}/uuid/${uuid}/custom-button/trigger/`

// the image path returned by old constants API endpoint
export const IMAGE_PATH = `/media/tmp/`

export const PRODVX_API_URL = "http://localhost:3535/v1"

export const setProDvxLedColorURL = (color: string, token: string) =>
  `${PRODVX_API_URL}/setAllLeds?lrgb=${color}&token=${token}`

export const getProDvxDeviceInfoURL = (token: string) =>
  `${PRODVX_API_URL}/getDeviceInfo?token=${token}`

export const getImageURL = (image: string) => `${API_URL}${image}`
