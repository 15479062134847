import React, { useCallback, useEffect, useState } from "react"

import { IS_DEMO_MODE } from "../../constants"
import { useCanCheckIntoMeetings } from "../../hooks/useCanCheckIntoMeetings"
import { useDialog } from "../../hooks/useDialog"
import { useNetworkStatus } from "../../hooks/useNetworkStatus"
import { canCancelMeeting, getTimeNow } from "../../utils"
import { newEndDate } from "./utils"
import { getTime } from "@joan/joan-core"

import { cancelMeetingDemo, finishMeetingDemo } from "../../redux/demoActions"
import {
  cancelMeeting,
  extendMeeting,
  finishMeeting,
  recalculateEvents,
} from "../../redux/events/actions"
import {
  selectEvents,
  selectIsBookingDisabled,
} from "../../redux/events/selectors"
import { interfaceConfigSelector } from "../../redux/selectors"
import { useAppDispatch, useAppSelector } from "../../redux/store"

import Dialog from "../../components/Dialog"
import CheckIntoMeetingCard from "./components/CheckIntoMeetingCard"
import CurrentMeetingCard from "./components/CurrentMeetingCard"
import NextMeetingCard from "./components/NextMeetingCard"
import OfflineCard from "./components/OfflineCard"

import "./style.sass"

const Home = () => {
  const dispatch = useAppDispatch()
  const { isOffline } = useNetworkStatus()

  const {
    constants,
    config,
    isFinishMeetLoading,
    isExtendMeetLoading,
    events,
    isBookingDisabled,
  } = useAppSelector((state) => ({
    constants: state.constants,
    config: interfaceConfigSelector(state.constants),
    isFinishMeetLoading: state.finishMeeting.isLoading,
    isExtendMeetLoading: state.extendMeeting.isLoading,
    events: selectEvents(state),
    isBookingDisabled: selectIsBookingDisabled(state),
  }))

  const currentMeeting = events.currentEvent
  const nextMeeting = events.nextEvent

  const currentEvent = currentMeeting ?? nextMeeting

  const canCheckIntoMeetings = useCanCheckIntoMeetings(currentEvent)

  const [selectedMeetId, setSelectedMeetId] = useState<string | null>(null)

  const { name = "", room_properties } = constants

  const {
    isOpenDialog: isOpenMeetDetailsDialog,
    title: meetDetailsTitle,
    content: meetDetailsContent,
    handleOpenDialog: handleOpenMeetDetailsDialog,
    handleCloseDialog: handleCloseMeetDetailsDialog,
    dialogRef: meetDetailsDialogRef,
    className: meetDetailsClassName,
  } = useDialog()

  const {
    isOpenDialog: isOpenMeetFinishDialog,
    content: meetFinishContent,
    handleOpenDialog: handleOpenMeetFinishDialog,
    handleCloseDialog: handleCloseMeetFinishDialog,
    dialogRef: meetFinishDialogRef,
    className: meetFinishClassName,
  } = useDialog()

  const {
    isOpenDialog: isOpenMeetExtendDialog,
    content: meetExtendContent,
    handleOpenDialog: handleOpenMeetExtendDialog,
    handleCloseDialog: handleCloseMeetExtendDialog,
    dialogRef: meetExtendDialogRef,
    className: meetExtendClassName,
  } = useDialog()

  const handleMeetFinishClick = (id: string) => setSelectedMeetId(id)

  const handleMeetEndSubmit = useCallback(async () => {
    if (!selectedMeetId) return

    const response = await (IS_DEMO_MODE
      ? dispatch(finishMeetingDemo(selectedMeetId))
      : dispatch(finishMeeting(selectedMeetId)))

    if (response?.ok) {
      handleCloseMeetFinishDialog()
    }
  }, [dispatch, handleCloseMeetFinishDialog, selectedMeetId])

  const handleMeetCancelSubmit = useCallback(async () => {
    if (!selectedMeetId) return

    const response = await (IS_DEMO_MODE
      ? dispatch(cancelMeetingDemo(selectedMeetId))
      : dispatch(cancelMeeting(selectedMeetId)))

    if (response?.ok) {
      handleCloseMeetFinishDialog()
    }
  }, [dispatch, handleCloseMeetFinishDialog, selectedMeetId])

  const handleMeetFinishSubmit = useCallback(() => {
    const now = getTimeNow()

    const shouldCancelNextMeeting =
      !!nextMeeting?.start &&
      now.isBefore(nextMeeting.start) &&
      canCheckIntoMeetings

    if (shouldCancelNextMeeting) {
      handleMeetCancelSubmit()
    } else {
      handleMeetEndSubmit()
    }
  }, [
    canCheckIntoMeetings,
    handleMeetCancelSubmit,
    handleMeetEndSubmit,
    nextMeeting,
  ])

  const handleMeetExtendSubmit = useCallback(async () => {
    if (!selectedMeetId || !currentMeeting) return

    const endDate = newEndDate(currentMeeting, nextMeeting).toISOString()

    const response = await dispatch(extendMeeting(selectedMeetId, endDate))

    if (response?.ok) {
      handleCloseMeetExtendDialog()
    }
  }, [
    currentMeeting,
    dispatch,
    handleCloseMeetExtendDialog,
    nextMeeting,
    selectedMeetId,
  ])

  const recalcEventsIfNeeded = useCallback(() => {
    if (currentMeeting) {
      const now = getTimeNow()
      const endDate = getTime(currentMeeting.end)

      if (endDate.isBefore(now)) {
        dispatch(recalculateEvents())
      }
    }
  }, [currentMeeting, dispatch])

  useEffect(() => {
    recalcEventsIfNeeded()
    const intervalId = setInterval(recalcEventsIfNeeded, 1000)

    return () => clearInterval(intervalId)
  }, [recalcEventsIfNeeded])

  if (isOffline) {
    return (
      <div className="Home Route" key="home-route">
        <OfflineCard roomName={name} />
      </div>
    )
  }

  return (
    <div className="Home Route" key="home-route">
      <CurrentMeetingCard
        roomName={name}
        event={currentEvent}
        roomProperties={room_properties}
        canCancelMeetings={
          canCancelMeeting(config, currentMeeting) && !isBookingDisabled
        }
        onOpenMeetDetailsDialog={handleOpenMeetDetailsDialog}
        onMeetFinishClick={handleMeetFinishClick}
        onOpenFinishMeetDialog={handleOpenMeetFinishDialog}
        onOpenExtendMeetDialog={handleOpenMeetExtendDialog}
        showStatusBar={!canCheckIntoMeetings}
      />

      {!canCheckIntoMeetings && (
        <NextMeetingCard
          event={nextMeeting}
          onOpenMeetDetailsDialog={handleOpenMeetDetailsDialog}
        />
      )}

      {currentEvent && canCheckIntoMeetings && (
        <CheckIntoMeetingCard
          event={currentEvent}
          canCancelMeetings={
            canCancelMeeting(config, currentEvent) && !isBookingDisabled
          }
          onMeetFinishClick={handleMeetFinishClick}
          onOpenFinishMeetDialog={handleOpenMeetFinishDialog}
        />
      )}

      {isOpenMeetDetailsDialog && (
        <Dialog
          ref={meetDetailsDialogRef}
          title={meetDetailsTitle}
          className={meetDetailsClassName}
          onClose={handleCloseMeetDetailsDialog}
        >
          {meetDetailsContent}
        </Dialog>
      )}

      {isOpenMeetFinishDialog && (
        <Dialog
          ref={meetFinishDialogRef}
          onClose={handleCloseMeetFinishDialog}
          onSubmit={handleMeetFinishSubmit}
          className={meetFinishClassName}
          isSubmitting={isFinishMeetLoading}
          showFooter
        >
          {meetFinishContent}
        </Dialog>
      )}

      {isOpenMeetExtendDialog && (
        <Dialog
          ref={meetExtendDialogRef}
          onClose={handleCloseMeetExtendDialog}
          onSubmit={handleMeetExtendSubmit}
          className={meetExtendClassName}
          isSubmitting={isExtendMeetLoading}
          showFooter
        >
          {meetExtendContent}
        </Dialog>
      )}
    </div>
  )
}

export default Home
