import React from "react"

import { nameOrEmail } from "../../utils"
import InlineSVG from "../InlineSVG"
import { t } from "@joan/joan-core"

import { Person } from "../../redux/events/types"

import PersonSVG from "../../assets/icons/person.svg"

import "./style.sass"

type Props = {
  organizer: Person
  showIcon?: boolean
  isOnSpot: boolean
}

const Organizer = ({ organizer, showIcon, isOnSpot }: Props) => {
  return (
    <p className="Organizer">
      {showIcon && (
        <InlineSVG>
          <PersonSVG />
        </InlineSVG>
      )}{" "}
      {isOnSpot ? t("Booked using Joan") : nameOrEmail(organizer)}
    </p>
  )
}

export default Organizer
