import React, { useState } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"

import { Trans } from "@joan/joan-core"

import Button from "../../../components/Button"

import ArrowLeftSVG from "../../../assets/icons/arrow_left.svg"
import ArrowRightSVG from "../../../assets/icons/arrow_right.svg"
import CheckSVG from "../../../assets/icons/check.svg"

import "./CalendarForm.sass"

type Props = {
  date: Dayjs
  onChangeDate: (date: Dayjs) => void
  onOpenInitialForm: () => void
}

const CalendarForm = ({ date, onChangeDate, onOpenInitialForm }: Props) => {
  const [currentMonth, setCurrentMonth] = useState(dayjs())

  const handlePrevMonth = () =>
    setCurrentMonth(currentMonth.subtract(1, "month"))

  const handleNextMonth = () => setCurrentMonth(currentMonth.add(1, "month"))

  const monthDays = generateMonthDays(currentMonth)
  const weekDays = generateWeekDays()

  return (
    <>
      <div className="form form-calendar">
        <div className="label">
          <Trans>Select date</Trans>
        </div>

        <div className="calendar">
          <div className="calendar__month">
            {currentMonth.format("MMMM YYYY")}
          </div>
          <div className="calendar__grid">
            {weekDays.map((day, index) => (
              <div className="calendar__weekday" key={index}>
                {day}
              </div>
            ))}

            {monthDays.map((day, index) => (
              <div
                key={index}
                onClick={() => onChangeDate(day)}
                className={classNames("calendar__day", {
                  "calendar__day--selected": date && date.isSame(day, "day"),
                  "calendar__day--current": day.isSame(dayjs(), "day"),
                  "calendar__day--prev": day.isBefore(dayjs(), "day"),
                  "calendar__day--disabled":
                    day.isBefore(dayjs(), "day") ||
                    day.isAfter(dayjs().add(6, "day"), "day"),
                })}
              >
                {day.date()}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="confirm-actions">
        <div className="confirm-actions-group">
          <Button className="PrevMonth" onClick={handlePrevMonth} isIconButton>
            <ArrowLeftSVG />
          </Button>

          <Button className="NextMonth" onClick={handleNextMonth} isIconButton>
            <ArrowRightSVG />
          </Button>
        </div>

        <Button className="Confirm" onClick={onOpenInitialForm} isIconButton>
          <CheckSVG />
        </Button>
      </div>
    </>
  )
}

export default CalendarForm

const generateMonthDays = (currentMonth: Dayjs) => {
  const startOfMonth = currentMonth.startOf("month")
  const endOfMonth = currentMonth.endOf("month")
  const days = []

  const prevDaysCount = startOfMonth.day() !== 0 ? startOfMonth.day() : 6
  for (let i = prevDaysCount; i > 1; i--) {
    days.push(startOfMonth.subtract(i, "day"))
  }

  for (let i = 0; i < endOfMonth.date(); i++) {
    days.push(startOfMonth.add(i, "day"))
  }

  const nextDaysCount = (7 - endOfMonth.day()) % 7
  for (let i = 1; i <= nextDaysCount; i++) {
    days.push(endOfMonth.add(i, "day"))
  }

  return days
}

const generateWeekDays = () => {
  const days = []
  for (let i = 1; i <= 7; i++) {
    days.push(dayjs().day(i).format("dd")) // We get the abbreviation of the day of the week depending on the locale
  }
  return days
}
