import React, { useCallback, useState } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useHistory } from "react-router-dom"

import { IS_DEMO_MODE, PATHS } from "../../constants"
import {
  eventConflicts,
  filterOptimistic,
  getTimeNow,
  orderByDate,
} from "../../utils"
import { availabilitySlots, availableDurations } from "./utils"
import { t, Trans } from "@joan/joan-core"

import { meetLaterDemo } from "../../redux/demoActions"
import { meetLater } from "../../redux/events/actions"
import { selectIsCheckInAllowed } from "../../redux/events/selectors"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import { confirmActionSuccess } from "../../redux/ui/actions"

import NavHeaderBar from "../../components/NavHeaderBar"
import CalendarForm from "./components/CalendarForm"
import InitialForm from "./components/InitialForm"
import TimeslotForm from "./components/TimeslotForm"

import "./style.sass"

export type FormValues = {
  date: Dayjs
  timeslot: {
    slot: number | null
    duration: number | null
  }
  title: string
}

type Step = "initial" | "selectDate" | "selectTimeslot"

const MeetLater = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const events = useAppSelector((state) =>
    orderByDate(filterOptimistic(state.events.events)),
  )
  const isCheckInAllowed = useAppSelector((state) =>
    selectIsCheckInAllowed(state),
  )

  const now = getTimeNow()
  const expiredSlots = Math.floor(now.minute() / 15)

  const [formValues, setFormValues] = useState<FormValues>({
    date: dayjs(),
    timeslot: {
      slot: null,
      duration: null,
    },
    title: t("Occupied"),
  })

  const slotCount = 96

  const [currentStep, setCurrentStep] = useState<Step>("initial")

  const currentSlot = now.valueOf()

  const {
    date,
    timeslot: { slot, duration },
    title,
  } = formValues

  const slots = availabilitySlots(
    events,
    now,
    currentSlot,
    slotCount,
    expiredSlots,
  )

  const durations = availableDurations(events, slot, 4)

  const nextAvailableSlot = slots.find(({ value, isDisabled }) => {
    const doesConflict = eventConflicts(events, value)
    return !doesConflict && !isDisabled
  })

  const initialSlot = eventConflicts(events, now)
    ? nextAvailableSlot
      ? nextAvailableSlot.value
      : null
    : currentSlot

  const selectedSlot = slot || initialSlot

  const initialDuration = durations[1]?.value ?? null
  const selectedDuration = duration || initialDuration

  const handleChangeDate = (selectedDate: Dayjs) =>
    setFormValues((prev) => ({ ...prev, date: selectedDate }))

  const handleChangeTimeslot = (timeslot: {
    slot: number | null
    duration: number | null
  }) => setFormValues((prev) => ({ ...prev, timeslot: { ...timeslot } }))

  const handleChangeTitle = (modifiedTitle: string) =>
    setFormValues((prev) => ({ ...prev, title: modifiedTitle }))

  const handleOpenInitialForm = () => setCurrentStep("initial")

  const handleConfirmClick = useCallback(async () => {
    const now = getTimeNow()
    const expiredSlots = Math.floor(now.minute() / 15)

    const currentSlot = now.valueOf()

    const slots = availabilitySlots(
      events,
      now,
      currentSlot,
      slotCount,
      expiredSlots,
    )

    const nextAvailableSlot = slots.find(({ value, isDisabled }) => {
      const doesConflict = eventConflicts(events, value)
      return !doesConflict && !isDisabled
    })

    const initialSlot = eventConflicts(events, now)
      ? nextAvailableSlot?.value || null
      : currentSlot

    const start = dayjs(slot || initialSlot)
      .set("year", date.year())
      .set("month", date.month())
      .set("date", date.date())

    const end = start.add(duration || 30, "minute")

    const isMeetNow = start.isBefore(now)
    const isAutoCheckinConfirm = isCheckInAllowed && isMeetNow

    if (start.isValid() && end.isValid()) {
      const response = await (IS_DEMO_MODE
        ? dispatch(meetLaterDemo(isMeetNow ? now : start, end, title))
        : dispatch(
            meetLater(
              isMeetNow ? now : start,
              end,
              title,
              isAutoCheckinConfirm,
            ),
          ))

      await dispatch(confirmActionSuccess())

      if (response?.ok) {
        history.push(PATHS.HOME)
      }
    }
  }, [events, slot, date, duration, isCheckInAllowed, dispatch, title, history])

  const meetLaterClassName = classNames({
    MeetLater: true,
    Route: true,
  })

  const renderStep = () => {
    switch (currentStep) {
      case "selectDate":
        return (
          <CalendarForm
            date={formValues.date}
            onChangeDate={handleChangeDate}
            onOpenInitialForm={handleOpenInitialForm}
          />
        )
      case "selectTimeslot":
        return (
          <TimeslotForm
            slots={slots}
            slot={selectedSlot}
            durations={durations}
            duration={selectedDuration}
            onChangeTimeslot={handleChangeTimeslot}
            onOpenInitialForm={handleOpenInitialForm}
          />
        )
      case "initial":
      default:
        return (
          <InitialForm
            title={title}
            date={date}
            slot={selectedSlot}
            duration={selectedDuration}
            onOpenDateStep={() => setCurrentStep("selectDate")}
            onOpenTimeslotStep={() => setCurrentStep("selectTimeslot")}
            onChangeTitle={handleChangeTitle}
            onSubmitConfirm={handleConfirmClick}
          />
        )
    }
  }

  return (
    <div className={meetLaterClassName}>
      <NavHeaderBar title={<Trans>Meet later</Trans>} isShowCloseButton />

      {renderStep()}
    </div>
  )
}

export default MeetLater
