import { Reducer, ReducerState } from "react"

import reduceReducers from "reduce-reducers"
import { AnyAction } from "redux"

import {
  filterOptimistic,
  findCurrentMeeting,
  isEventExtended,
} from "../../utils"
import {
  CANCEL_MEETING_ERROR,
  CANCEL_MEETING_START,
  CANCEL_MEETING_SUCCESS,
  FETCH_CALENDAR_ERROR,
  FETCH_CALENDAR_SUCCESS,
  FINISH_MEETING_ERROR,
  FINISH_MEETING_START,
  FINISH_MEETING_SUCCESS,
  MEET_LATER_ERROR,
  MEET_LATER_START,
  MEET_LATER_SUCCESS,
  MEET_NOW_ERROR,
  MEET_NOW_START,
  MEET_NOW_SUCCESS,
} from "./constants"
import { CalendarEvent, ExtendedEvent } from "./types"
import { getNextEventFromNow, getTime } from "@joan/joan-core"

type EventsState = {
  events: CalendarEvent[]
  currentEvent: CalendarEvent | null
  nextEvent: CalendarEvent | null
  confirmed: string[]
  isLoaded: boolean
}

const initialEventsState: EventsState = {
  events: [],
  currentEvent: null,
  nextEvent: null,
  confirmed: [],
  // isLoaded is used to determine if the calendar has been fetched at least once
  isLoaded: false,
}

const eventsReducer = (
  state: EventsState = initialEventsState,
  action: AnyAction,
): EventsState => {
  const { type, payload } = action

  switch (type) {
    case FETCH_CALENDAR_SUCCESS:
      const remainEvents = state.events.filter(
        (ev) => isEventExtended(ev) && ev.isOptimistic,
      )
      const optimisticEventIds = remainEvents.map((ev) => ev.id)
      const updatedEvents = payload.events.filter(
        (ev: CalendarEvent) => optimisticEventIds.indexOf(ev.id) === -1,
      )
      const confirmed = payload.confirmed ?? []

      return {
        ...state,
        events: [...remainEvents, ...updatedEvents],
        confirmed,
        isLoaded: true,
      }

    case FETCH_CALENDAR_ERROR:
      if (!payload.setup) {
        return state
      } else {
        return { ...state, events: [], confirmed: [] }
      }

    // Optimistic: Meet Now
    case MEET_NOW_START: {
      const optimisticEvent = {
        ...payload,
        start: payload.start,
        end: payload.end,
        isOptimistic: true,
        isMeetNow: true,
      }
      return { ...state, events: [optimisticEvent, ...state.events] }
    }
    case MEET_NOW_SUCCESS: {
      const remainEvents = state.events.filter(
        (ev) => !(isEventExtended(ev) && ev.isOptimistic && ev.isMeetNow),
      )
      return { ...state, events: [payload, ...remainEvents] }
    }
    case MEET_NOW_ERROR: {
      const remainEvents = state.events.filter(
        (ev) => !(isEventExtended(ev) && ev.isOptimistic && ev.isMeetNow),
      )
      return { ...state, events: [...remainEvents] }
    }

    // Optimistic: Meet Later
    case MEET_LATER_START:
      const optimisticEvent = {
        ...payload,
        start: payload.start.toISOString(),
        end: payload.end.toISOString(),
        summary: payload.title,
        isOptimistic: true,
        isMeetLater: true,
      }

      return {
        ...state,
        events: [optimisticEvent, ...state.events],
      }

    case MEET_LATER_SUCCESS: {
      const remainEvents = state.events.filter(
        (ev) => !(isEventExtended(ev) && ev.isOptimistic && ev.isMeetLater),
      )
      return {
        ...state,
        events: [payload, ...remainEvents],
        confirmed: [
          ...state.confirmed.filter((id) => id !== payload.id),
          payload.id,
        ],
      }
    }

    case MEET_LATER_ERROR: {
      const remainEvents = state.events.filter(
        (ev) => !(isEventExtended(ev) && ev.isOptimistic && ev.isMeetLater),
      )
      return { ...state, events: [...remainEvents] }
    }

    // Optimistic: Finish Meeting
    case FINISH_MEETING_START: {
      const optimisticEvent = state.events.find((ev) => ev.id === payload)
      const remainEvents = state.events.filter((ev) => ev.id !== payload)

      if (!optimisticEvent) {
        return state
      }

      return {
        ...state,
        events: [
          {
            ...optimisticEvent,
            isFinishMeeting: true,
            isOptimistic: true,
          },
          ...remainEvents,
        ],
      }
    }
    case FINISH_MEETING_SUCCESS: {
      return {
        ...state,
        events: state.events.filter((ev) => ev.id !== payload.id),
      }
    }

    case FINISH_MEETING_ERROR: {
      const optimisticEvent = state.events.find(
        (ev) => isEventExtended(ev) && ev.isFinishMeeting && ev.isOptimistic,
      ) as ExtendedEvent
      const remainEvents = state.events.filter((ev) => ev !== optimisticEvent)
      const {
        isOptimistic,
        isMeetNow,
        isMeetLater,
        isFinishMeeting,
        isCancelMeeting,
        ...restoredFinishedEvent
      } = optimisticEvent
      return { ...state, events: [restoredFinishedEvent, ...remainEvents] }
    }

    // Optimistic: Cancel Meeting
    case CANCEL_MEETING_START: {
      const optimisticEvent = state.events.find((ev) => ev.id === payload)
      const remainEvents = state.events.filter((ev) => ev.id !== payload)

      if (!optimisticEvent) {
        return state
      }

      return {
        ...state,
        events: [
          {
            ...optimisticEvent,
            isCancelMeeting: true,
            isOptimistic: true,
          },
          ...remainEvents,
        ],
      }
    }

    case CANCEL_MEETING_SUCCESS: {
      return {
        ...state,
        events: state.events.filter((ev) => ev.id !== payload.id),
      }
    }

    case CANCEL_MEETING_ERROR: {
      const optimisticEvent = state.events.find(
        (ev) => isEventExtended(ev) && ev.isCancelMeeting && ev.isOptimistic,
      ) as ExtendedEvent
      if (!optimisticEvent) {
        return state
      }

      const remainEvents = state.events.filter((ev) => ev !== optimisticEvent)
      const {
        isOptimistic,
        isMeetNow,
        isMeetLater,
        isFinishMeeting,
        isCancelMeeting,
        ...restoredCanceledEvent
      } = optimisticEvent

      return { ...state, events: [restoredCanceledEvent, ...remainEvents] }
    }

    default:
      return state
  }
}

const currentAndNextReducer = (state: EventsState, action: AnyAction) => {
  const { events, currentEvent, nextEvent } = state
  const { type } = action
  switch (type) {
    case "RECALCULATE_EVENTS":
    case FETCH_CALENDAR_SUCCESS:
    case MEET_NOW_START:
    case MEET_NOW_SUCCESS:
    case MEET_NOW_ERROR:
    case MEET_LATER_START:
    case MEET_LATER_SUCCESS:
    case MEET_LATER_ERROR:
    case FINISH_MEETING_START:
    case FINISH_MEETING_SUCCESS:
    case FINISH_MEETING_ERROR:
    case CANCEL_MEETING_START:
    case CANCEL_MEETING_SUCCESS:
    case CANCEL_MEETING_ERROR:
      const now = new Date()
      const filteredEvents = filterOptimistic(events)
      const newCurrentEvent = findCurrentMeeting(filteredEvents, now)
      const currentDiffers = currentEvent !== newCurrentEvent
      const newNextEvent = getNextEventFromNow(
        filteredEvents,
        getTime(now),
      ) as CalendarEvent
      const nextDiffers = nextEvent !== newNextEvent
      if (currentDiffers || nextDiffers) {
        return {
          ...state,
          currentEvent: newCurrentEvent ?? null,
          nextEvent: newNextEvent ?? null,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default eventsReducer

export const eventsWithCurrentAndNext = reduceReducers(
  initialEventsState,
  eventsReducer,
  currentAndNextReducer,
)
