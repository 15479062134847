import React from "react"

import useOrientation from "../../../hooks/useOrientation"
import { t, Trans } from "@joan/joan-core"

import Card from "../../../components/Card"
import Logo from "../../../components/Logo"
import MeetingTitle from "../../../components/MeetingTitle"

import WiFiOffSVG from "../../../assets/icons/wifi_off.svg"

import "./OfflineCard.sass"

type OfflineCardProps = {
  roomName: string
}

const OfflineCard = ({ roomName }: OfflineCardProps) => {
  const { isPortrait } = useOrientation()
  return (
    <Card className="OfflineCard">
      <div className="room-details">
        <div className="room-details-header">
          <div className="room-name">{roomName}</div>

          {isPortrait && <Logo variant="horizontal" />}
        </div>

        <div className="room-details-body">
          <div className="room-details-item">
            <WiFiOffSVG />
            <Trans>Offline</Trans>
          </div>
        </div>
      </div>
      <div className="meeting-info">
        <MeetingTitle>{t("N/A")}</MeetingTitle>
      </div>
    </Card>
  )
}

export default OfflineCard
